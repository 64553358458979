export const ChatInput = 
localStorage.getItem('HealthTool') ==='true' || localStorage.getItem('HealthTool') === true ?
[
    {
        "query": "memberPortal",
        "options": [{ message: "Member Portal", intent: 'memberPortal' }],
    },

    {
        "query": "healthtool_card",
        "options": [{ message: "Show me my Health Tool Card", intent: 'Health Tool' }],
    },
    {
        "query": "call_agent",
        "options": [{ message: "Call Agent", intent: 'Call' }],
    },
    {
        "query": "email_agent",
        "options": [{ message: "Email Agent", intent: 'Email' }],
    },
    {
        "query": "card",
        "options": [{ message: 'Show me my Membership ID', intent: 'Membership ID' },
        { message: "Show me my Health Tool Card", intent: 'Health Tool' },
        { message: "Show me my Payment Card", intent: 'Payment Card' }],
    },
    {
        "query": "digitalCard",
        "options": [{ message: 'Show me my Membership ID', intent: 'Membership ID' },
        { message: "Show me my Health Tool Card", intent: 'Health Tool' },
        { message: "Show me my Payment Card", intent: 'Payment Card' }],
    },
    {
        "query": "transactions",
        "options": [{ message: 'Show me my Transactions', intent: 'Transactions' }],
    }
    ,
    {
        "query": "find_provider",
        "options": [{ message: 'Find a provider', intent: 'find_provider' }],
    }
    ,
    {
        "query": "guidelines_document",
        "options": [{ message: 'Show me my Documents', intent: 'documents' }],
    },

    {
        "query": "notifications",
        "options": [{ message: 'Show me my Notifications', intent: 'notifications' }],
    },

    {
        "query": "telemed",
        "options": [{ message: 'Open Telemed', intent: 'telemed' }],
    },
    {
        "query": "payment_card",
        "options": [{ message: "Show me my Payment Card", intent: 'Payment Card' }],
    },
    {
        "query": "program_information",
        "options": [{ message: "Show me my Program Information", intent: 'program information' }],
    },
    {
        "query": "print_IDCard",
        "options": [{ message: 'Print my Membership ID', intent: 'Membership ID' },
        { message: "Print my Health Tool Card", intent: 'Health Tool' },
        { message: "Print my Payment Card", intent: 'Payment Card' }],

    },
    {
        "query": "print_IDCard",
        "options": [{ message: 'Print my Membership ID', intent: 'Membership ID' },
        { message: "Print my Health Tool Card", intent: 'Health Tool' },
        { message: "Print my Payment Card", intent: 'Payment Card' }],
    }
] : [
    {
        "query": "memberPortal",
        "options": [{ message: "Member Portal", intent: 'memberPortal' }],
    },

    {
        "query": "healthtool_card",
        "options": [{ message: "Show me my Health Tool Card", intent: 'Health Tool' }],
    },
    {
        "query": "call_agent",
        "options": [{ message: "Call Agent", intent: 'Call' }],
    },
    {
        "query": "email_agent",
        "options": [{ message: "Email Agent", intent: 'Email' }],
    },
    {
        "query": "card",
        "options": [{ message: 'Show me my Membership ID', intent: 'Membership ID' },
        { message: "Show me my Payment Card", intent: 'Payment Card' }],
    },
    {
        "query": "digitalCard",
        "options": [{ message: 'Show me my Membership ID', intent: 'Membership ID' },
        { message: "Show me my Payment Card", intent: 'Payment Card' }],
    },
    {
        "query": "transactions",
        "options": [{ message: 'Show me my Transactions', intent: 'Transactions' }],
    }
    ,
    {
        "query": "find_provider",
        "options": [{ message: 'Find a provider', intent: 'find_provider' }],
    }
    ,
    {
        "query": "guidelines_document",
        "options": [{ message: 'Show me my Documents', intent: 'documents' }],
    },

    {
        "query": "notifications",
        "options": [{ message: 'Show me my Notifications', intent: 'notifications' }],
    },

    {
        "query": "telemed",
        "options": [{ message: 'Open Telemed', intent: 'telemed' }],
    },
    {
        "query": "payment_card",
        "options": [{ message: "Show me my Payment Card", intent: 'Payment Card' }],
    },
    {
        "query": "program_information",
        "options": [{ message: "Show me my Program Information", intent: 'program information' }],
    },
    {
        "query": "print_IDCard",
        "options": [{ message: 'Print my Membership ID', intent: 'Membership ID' },
        { message: "Print my Payment Card", intent: 'Payment Card' }],

    },
    {
        "query": "print_IDCard",
        "options": [{ message: 'Print my Membership ID', intent: 'Membership ID' },
        { message: "Print my Payment Card", intent: 'Payment Card' }],
    }
]

export const splitMulti = (str, tokens) => {
    var tempChar = tokens[0]; // We can use the first token as a temporary join character
    for (var i = 1; i < tokens.length; i++) {
        str = str.split(tokens[i]).join(tempChar);
    }
    str = str.split(tempChar);
    return str;
}
export const splitMessage = (message) => {
    let list = splitMulti(message, ["AIKB - ", ",url - ", "typeofintent - ", ",query - "]);

    return list;
}