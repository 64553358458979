import React from "react";
import Button from '@material-ui/core/Button';
import { Auth } from "aws-amplify";
import axios from "axios";
import PasswordValidations from "./PwdValidations";
import Loader from "../Styles/Loader";

export default class FirstPasswordChange extends React.Component{
constructor(props){
    super(props);
    this.state={
        firstPassword:'',
        secondPassword:'',
        isReveal2:true, 
        isReveal: true,
        disableSendBtn:true,
        error:false,
    }
}
toggleResetBtn = (allPassed)=> {
    this.setState({disableSendBtn: !allPassed})
  }
render(){
    return (<div style={{marginBottom:50}}>
         {this.state.loader?<Loader/>:null}
        <div className="login_input_container">
                <div class="firsttime_changepassword_text">Change Password</div>
                <div class="firsttime_changepassword_subtext">Please enter your new password below.</div>
                {this.state.error && <p className="login_new_error_text">{this.state.error}</p>}
        <input
            className="login_input_username"
            id="passw"
            type="password"
            value={this.state.firstPassword}
            onChange={(e) => this.setState({firstPassword:e.target.value})}
            placeholder="ENTER NEW PASSWORD"
            name="password"
          //   ref={this.passwordInput}
            required
          //   onChange={this.handleChange.bind(this)}
            onKeyDown={this.handleKeyUp}
          />
          {/* <span className="p-viewer-one-firsttime" onClick={() => this.togglePasswords('passw')}>
          {this.state.isReveal ?
          <i className="fa fa-eye-slash" aria-hidden="true" ></i>:
            <i className="fa fa-eye" aria-hidden="true" ></i> 
          }
          </span> */}
         
           <input
            className="login_input_username"
            id="pass"
            type="password"
            value={this.state.secondPassword}
            onChange={(e) => this.setState({secondPassword:e.target.value})}
            placeholder="ENTER NEW PASSWORD"
            name="password"
          //   ref={this.passwordInput}
            required
          //   onChange={this.handleChange.bind(this)}
            onKeyDown={this.handleKeyUp}
          />
          {/* <span className="p-viewer-firsttime" onClick={() => this.togglePassword('pass')}>
          {this.state.isReveal2 ?
          <i className="fa fa-eye-slash" aria-hidden="true" ></i>:
            <i className="fa fa-eye" aria-hidden="true" ></i> 
          }
          </span> */}
        
          <Button
            type="submit"
            class="login_button"
            onClick={() => this.handleConfirmVerification()}
          > SEND</Button>
            <div className="center">
                <PasswordValidations password={this.state.firstPassword} allPassed={(pass)=>this.toggleResetBtn(pass)}/>
            </div>
  </div>
    </div>)
}
handleConfirmVerification = e => {
   e && e.preventDefault();
   if(!this.state.disableSendBtn){
    if (this.state.firstPassword == this.state.secondPassword) {
        this.setState({loader:true})
        Auth.completeNewPassword(
            this.props.user, // the Cognito User Object
            this.state.firstPassword // the new password
          ) .then(user => {
            window.location.reload();
          }).catch(err => {
            this.setState({error:err.message, loader:false})
          })
    }else{
        this.setState({error:'Please enter same password'})
    }}else{
        this.setState({error:'Please enter valid Password'})
    }
}
togglePassword=(id)=>{
    this.setState({isReveal2:!this.state.isReveal2})
    var pass=document.getElementById(id)
    if(pass.type=='password'){
      pass.type="text"
    }else{
      pass.type="password"
    }
  }
  togglePasswords=(id)=>{
    this.setState({isReveal:!this.state.isReveal})
    var pass=document.getElementById(id)
    if(pass.type=='password'){
      pass.type="text"
    }else{
      pass.type="password"
    }

  }
}