import { Component } from 'react';
import UHSLogo from './Image/UHS_Logo_RGB_600px_200px.png';
import PHSLogo from './Image/phcs_id_practitioner_ancillary_only.jpg'
import AFMCLogo from './Image/AZFMC_logo.png'
import './MemberInformation.css';
import { Grid } from '@material-ui/core'
import moment from 'moment'
export default class IDCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: this.props.data,
      membersince: '',
      prefix: '',
      groupno: '',
      contactNumber: '',
      moreUserData: '',
    }
  }
  componentDidMount() {
    this.dateformat();
    console.log("DATA =====", this.state.data)
    console.log("DATA =====", this.state.data.memberIdCardList[0])
    let userData = this.state.data.memberIdCardList[0];
    let tempArr = [];
    if (userData) {
      userData.planInfo.map((info, index) => {
        if (info.idcardField == "prefix") {
          this.setState({ prefix: info.fieldValue })
        }
        if (info.idcardField == "group") {
          this.setState({ groupno: info.fieldValue })
        }
        if (info.idcardField == "contact number") {
          this.setState({ contactNumber: info.fieldValue })
        }
        if (info.idcardField != "group" && info.idcardField != "prefix" && info.idcardField != "card id" && info.idcardField != "contact number" && info.idcardField != "Program Details") {
          tempArr.push(info);
        }
      });
    }
    console.log("tempArr =====", tempArr)
    this.setState({ moreUserData: tempArr })

  }
  dateformat() {
    let enrollmentDate = this.getDateInUTC(this.props.data.memberIdCardList[0].enrollmentDate, true)
    var day = moment(enrollmentDate).format('DD')
    var mon = moment(enrollmentDate).format('MM')
    var year = moment(enrollmentDate).format('YYYY')
    var date = mon + "/" + day + "/" + year
    this.setState({ membersince: date })
  }
  // {this.state.data.memberIdCardList[0].firstName}
  //<div className="fv_background_image"></div>
  render() {
    return (
      <div className="fview">
        <div className={(this.state.data.memberIdCardList[0].clientId == '6548' || this.state.data.memberIdCardList[0].clientId == 6548) || (this.state.data.memberIdCardList[0].clientId == '4367' || this.state.data.memberIdCardList[0].clientId == 4367)
          || (this.state.data.memberIdCardList[0].clientId == '5540' || this.state.data.memberIdCardList[0].clientId == 5540)
          || (this.state.data.memberIdCardList[0].clientId == '4376' || this.state.data.memberIdCardList[0].clientId == 4376) ? 'fv_background_image fv_background_image_blue' : 'fv_background_image'}>
          <div className="row rowMargin cardheaderLogo">
            <div className="col-6 col-md-6">
              <img src={UHSLogo} class="fv_left_logo fv_img-fluid" />
            </div>
            <div className="col-6 col-md-6 rightlogo">
              {this.props.network == 'PHCS' || this.props.network == 'smartshare25' || this.props.network == 'smartshare50' || this.props.network == 'healthyLife' ?
                <img src={PHSLogo} class="fv_right_logo  fv_img-fluid" /> : null}
              {this.props.network == 'AFMC' ?
                <img src={AFMCLogo} class="fv_right_logo  fv_img-fluid" /> : null}
            </div>
          </div>
          <div className="row rowMargin memberDetailsWrapper">
            <div className="col-6 col-md-6">
              {this.state.data.memberIdCardList && this.state.data.memberIdCardList.length > 0 ?
                <div class="memberitemRow">
                  <h5>{this.state.data.memberIdCardList[0].firstName} {this.state.data.memberIdCardList[0].lastName}</h5>
                  <h6>{this.state.data.memberIdCardList[0].planInfo.map(res => (res.idcardField === "prefix" ? res.fieldValue : null))}{this.state.data.memberIdCardList[0].empId}</h6>
                </div> : null
              }
              <div className="dependentContainer userDetailLeft">
                {this.state.data.memberIdCardList && this.state.data.memberIdCardList.map((data) =>
                  <div class="row memberdependentItemRow">
                    <div className="col-md-7">
                      <h5>{data.firstName} {data.lastName}</h5>
                    </div>
                    <div className="col-md-5">
                      <h6>{this.state.data.memberIdCardList[0].planInfo.map(res => (res.idcardField === "prefix" ? res.fieldValue : null))}{data.empId}</h6>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-6 col-md-6 dependentContainer userDetailRight">
              <h4>Program Details</h4>
              <div class="row memberdependentItemRow">
                <div className="col-6 col-md-6"><h5>Member since</h5></div>
                <div className="col-6 col-md-6"><h6>{this.state.membersince}</h6></div>
              </div>
              <div class="row memberdependentItemRow">
                <div className="col-6 col-md-6"><h5>Group No.</h5></div>
                <div className="col-6 col-md-6"><h6>{this.state.groupno}</h6></div>
              </div>
              <div class="row memberdependentItemRow">
                <div className="col-6 col-md-6"><h5>Program ID</h5></div>
                <div className="col-6 col-md-6"><h6>{this.state.data.memberIdCardList[0].planId}</h6></div>
              </div>

              <div className="moreUserDataWrapper">
                {this.state.moreUserData && this.state.moreUserData.map((data) =>
                  <div class="row memberdependentItemRow">
                    <div className="col-6 col-md-6"><h5 dangerouslySetInnerHTML={{ __html: data.idcardField }}></h5></div>
                    <div className="col-6 col-md-6"><h6 dangerouslySetInnerHTML={{ __html: data.fieldValue }}></h6></div>
                  </div>
                )}
              </div>

            </div>
            <div className="col-md-12 cardBottomContent">
              {(() => {
                switch (this.props.network) {
                  case 'PHCS':
                    return (
                      <div class="planFooter">
                        Universal HealthShare plans from Universal Health Fellowship are health care cost sharing ministry programs, not insurance. We are IN NETWORK for Multiplan/PHCS Practitioner & Ancillary Network. Providers & Members confirmations and questions {this.state.contactNumber}
                      </div>
                    )
                  case 'healthyLife':
                    return (
                      <div class="planFooter">
                        Universal HealthShare plans from Universal Health Fellowship are health care cost sharing ministry programs, not insurance. We are IN NETWORK for MultiPlan’s PHCS Network for practitioner & ancillary providers. Providers & Members confirmations and questions {this.state.contactNumber}
                      </div>
                    )

                  case 'smartshare25':
                    return (
                      <div class="planFooter">
                        Universal HealthShare plans from Universal Health Fellowship are health care cost sharing ministry programs, not insurance. We are IN NETWORK for MultiPlan’s PHCS Network for practitioner & ancillary providers. Providers & Members confirmations and questions {this.state.contactNumber}

                      </div>
                    )
                  case 'smartshare50':
                    return (
                      <div class="planFooter">
                        Universal HealthShare plans from Universal Health Fellowship are health care cost sharing ministry programs, not insurance. We are IN NETWORK for MultiPlan’s PHCS Network for practitioner & ancillary providers. Providers & Members confirmations and questions {this.state.contactNumber}

                      </div>
                    )

                  case 'Smartshare':
                    return (
                      <div class="planFooter">
                        Universal HealthShare plans from Universal Health Fellowship are health care cost sharing ministry programs, not insurance. We are IN NETWORK for Multiplan/PHCS Practitioner & Ancillary Network. Providers & Members confirmations and questions {this.state.contactNumber}

                      </div>
                    )

                  case 'AFMC':
                    return (
                      <div class="planFooter">
                        Universal HealthShare plans from Universal Health Fellowship are health care cost sharing ministry programs, not insurance. We are IN NETWORK for The Arizona Foundation for physician and ancillary services. Providers & Members confirmations and questions {this.state.contactNumber}
                      </div>
                    )
                }
              })()}
            </div>
          </div>
        </div>
      </div>
      //         <div className="fview">
      //            <Grid container>
      //              <div className="fv_background_image">
      //              <Grid item md={12}>
      //                <div className="fv_top_image_container">
      //                   <Grid item md={6}>
      //                     <img src={UHSLogo} class="fv_left_logo img-fluid" />
      //                  </Grid>
      //                     <Grid item md={6}>
      //                     {this.props.network =='PHCS' || this.props.network =='smartshare25' || this.props.network =='smartshare50' || this.props.network =='healthyLife'?
      //                       <img src={PHSLogo} class="fv_right_logo  img-fluid"/>:null}
      //                     </Grid>
      //                </div>
      //                </Grid>
      //                <Grid item md={12}>
      //                <div class="fv_card">
      //                <Grid item md={6}>
      //                 <div class="fv_left_container">
      //                 {this.state.data.memberIdCardList && this.state.data.memberIdCardList.map((data)=>
      //                     <div class="fv_list_view">
      //                     <Grid item md={3}><div class="fv_list_adultname">{data.firstName} {data.lastName}</div></Grid>
      //                     <Grid item md={3}> <div class="fv_list_username">{this.props.data && this.props.data.memberIdCardList[0].planInfo.map(res=>(res.idcardField === "prefix"?res.fieldValue:null))}{data.empId}</div></Grid>
      //                     </div>)}
      //                 </div>
      //               </Grid>
      //               <Grid item md={6}>
      //                 <div class="fv_right_container">
      //                   <div class="fv_joined_text">Program Details</div>
      //                   <div class="fv_right_table1">
      //                   <Grid item md={3}>
      //                     <div class="fv_left_table">
      //                       <div class="fv_table_left_text">Member since</div>
      //                       {this.state.data.memberIdCardList[0].groupNo && <div class="fv_table_left_text">Group No.</div>}
      //                       <div class="fv_table_left_text">Program ID</div>
      //                     </div>
      //                     </Grid>
      //                     <Grid item md={3}>
      //                     <div class="fv_right_table">
      //                       <div class="fv_table_right_text">{this.state.membersince}</div>
      //                       <div class="fv_table_right_text">{this.state.data.memberIdCardList[0].groupNo}</div>
      //                       <div class="fv_table_right_text">{this.state.data.memberIdCardList[0].planId}</div>
      //                     </div>
      //                     </Grid>
      //                   </div>
      //                 </div>
      //                 </Grid>
      //                 <div class="fv_space_2table"></div>
      //                </div>
      //                </Grid>
      //                <Grid item md={12}>
      //                   {(() => {
      //                 switch ( this.props.network) {
      //                   case 'PHCS':
      //                     return (
      //                       <div class="fv_center_details_text">
      //                       Universal HealthShare plans from Universal Health Fellowship are health care cost sharing ministry programs, not insurance. We are IN NETWORK for Multiplan/PHCS Practitioner & Ancillary Network. 
      //                       <br/>Providers & Members confirmations and questions 
      //                   </div>
      //                     )
      //                     case 'healthyLife':
      //                       return (
      //                         <div class="fv_center_details_text">
      //                         Universal HealthShare plans from Universal Health Fellowship are health care cost sharing ministry programs, not insurance. We are IN NETWORK for MultiPlan’s PHCS Network for practitioner & ancillary providers.
      //                         <br/> Providers & Members confirmations and questions &nbsp; 
      //                     </div>
      //                       )

      //                   case 'smartshare25':
      //                     return (
      //                       <div class="fv_center_details_text">
      //                       Universal HealthShare plans from Universal Health Fellowship are health care cost sharing ministry programs, not insurance. We are IN NETWORK for MultiPlan’s PHCS Network for practitioner & ancillary providers.
      //                       <br/> Providers & Members confirmations and questions

      //                   </div>
      //                     )
      //                     case 'smartshare50':
      //                       return (
      //                         <div class="fv_center_details_text">
      //                         Universal HealthShare plans from Universal Health Fellowship are health care cost sharing ministry programs, not insurance. We are IN NETWORK for MultiPlan’s PHCS Network for practitioner & ancillary providers. 
      //                         <br/>Providers & Members confirmations and questions &nbsp;

      //                     </div>
      //                       )

      //                   case 'Smartshare':
      //                     return (
      //                       <div class="fv_center_details_text">
      //                       Universal HealthShare plans from Universal Health Fellowship are health care cost sharing ministry programs, not insurance. We are IN NETWORK for Multiplan/PHCS Practitioner & Ancillary Network. Providers & Members confirmations and questions 

      //                   </div>
      //                     )

      //                   case 'AFMC':
      //                     return (
      //                       <div class="fv_center_details_text">
      //                       Universal HealthShare plans from Universal Health Fellowship are health care cost sharing ministry programs,
      // not insurance. We are IN NETWORK for The Arizona Foundation for physician and ancillary services. Providers & Members confirmations and questions 
      //                   </div>
      //                     )
      //                 }
      //               })()}
      //                   </Grid>
      //              </div>
      //              </Grid>
      //         </div>
    )
  }
  getDateInUTC = (date, getInMillisecs) => {
    if (date) {
      let newDateTime = date + new Date(date).getTimezoneOffset() * 60 * 1000;
      if (getInMillisecs) {
        return newDateTime
      }
      return new Date(newDateTime)
    }
    return date
  }
}