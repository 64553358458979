import { Component } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NoticesIcon from '../../Assets/notices-icon-active.svg';
import notifNoticesIcon from '../../Assets/notification/notification_notice_icon.svg';
import NoticeIcon from '../../Assets/notices-icon-active.svg';
import AnnouncementNotification from '../../Assets/notification/notification_announcement_icon.svg';
import CloseIcon from '@material-ui/icons/Close';
import firebase from '../../firebase';
import axios from 'axios';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import { Modal } from 'react-bootstrap';
import { Grid, IconButton } from "@material-ui/core";
export default class Notices extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarOpen: false,
      dockval: false,
      rightslider: false,
      rightdockval: false,
      showHealthCard: false,
      bigcard: '',
      smallcard: '',
      rightcard: '',
      x: false,
      yellowPopup: false,
      digitalcard: false,
      documentsmodal: false,
      contactmodal: false,
      mqmoduleAlert: null,
      mqloader: false,
      myneedsfooter: false,
      contactCardData: null,
      documentCardData: null,
      tempNotAvailableModal: false,
      notificationData: [],
      unReadData: [],
      noticeData: [],
      notificationCount: 0,
      anchorEl: null,
      open: false,
      show: false,
      showhealthtools: false,
      content: '',
      header: '',
      publishDate: '',
      noticeModal: false,
      URL: null,
      Type: null
    }

  }
  componentDidMount() {

    // this.getNotification()
    this.getAnnouncemet()
    var popupShow = localStorage.getItem('popupShow')
    this.setState({ yellowPopup: popupShow });

    const msg = firebase.messaging.isSupported() ? firebase.messaging() : null;
    if (msg) {

      msg.onMessage(payload => {
        // this.getNotification();
        this.getAnnouncemet()
        const myEvent = new CustomEvent("myevent", {
          detail: {},
          bubbles: true,
          cancelable: true,
          composed: false,
        })
        document.dispatchEvent(myEvent);
        console.log("onMessage:+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++home ", payload);
      });
    }
  }
  getAnnouncemet = () => {
    axios.get(process.env.REACT_APP_BASE_URL + "v8/Twillio/" + "getAnnouncemetDetails")
      .then(res => {
        if (res.data && res.data.response) {
          this.setState({
            notificationData: res.data.response.notificationDetails.sort((a, b) => -(a.type.localeCompare(b.type))),
            notificationCount: res.data.response.count
          }, () => {
            console.log("sort data--", this.state.notificationData)
            if (this.state.notificationData) {
              let noticeData = this.state.notificationData.filter((val, index) => (val.type == 'Notices'))
              let dt = this.state.notificationData.filter((val, index) => (val.type == 'ANNOUNCEMENT'))
              let content = this.state.notificationData.filter((val, index) => (val.message))

              let newArr = dt.slice(0, 5)
              this.setState({ unReadData: this.state.notificationData, noticeData: noticeData })
            }

          })
        } else {
          this.setState({ notificationData: [] })
        }

      })
  }
  render() {
    return (<div>
      <div className="cardwallet_back_notification">
        <div className="NotificationCard-BG text-center">
          <div className="cardwallet_onover_backdivMain">
            <img src={NoticesIcon} className="cardwaalet_img_backside" />
            <div className="notification_label_backside">Announcements & Notices</div>
            <div style={{ paddingLeft: 0 }} class="crossButton"> {this.props.modal ? <CloseIcon onClick={() => this.props.closeModal()} style={{ color: '#818181', cursor: "pointer" }} /> : <MoreVertIcon style={{ color: '#818181' }} />}</div>
          </div>
        </div>
        {/* <div className="notices">
                           <div className="cardwallet_onover_backdiv">
                               <img src={notifNoticesIcon} className="Notification_category_img"/>
                               <div className="notification_category_Message">
                                   <p className="notification_category_label">NOTICE
                                   <span class="notification_details_Date">Jul 20</span>
                                   </p>
                                   <div style={{color: 'rgb(78, 78, 78)'}}>Important changes to Sharing Guidelines</div>
                               </div>
                           </div>
                        </div>   */}
        {
          this.state.notificationData.length > 0 ?
            <div className="notificationTray">
              {
                this.state.notificationData.slice(0, 5).map((data, idx) => {
                  return (data.type == 'NOTICE' || data.type == 'Notice' || data.type == 'notice' || data.type == 'NOTICES' || data.type == 'Notices' || data.type == 'notices') ?
                    <div key={idx} class="cardwallet_onover_backdiv" onClick={() => this.onClickUpdateNotificationStatus(data.title, data.message, data.urlPath, data.type, this.publishDateformat(data.createdDate))}>
                      <img src={notifNoticesIcon} class="Notification_category_img" />
                      <div class="notification_category_Message">
                        <p class="notification_category_label">{data.type}
                          <span className="notification_details_Date">{this.dateformat(data.createdDate)}</span>

                        </p>
                        <div style={{ color: '#4e4e4e', lineHeight: '1.3' }}>{data.title}</div>
                      </div>
                    </div>
                    :

                    // (data.type == 'ANNOUNCEMENT' || data.type == 'announcement') ?
                    // <div key={idx} class="cardwallet_onover_backdiv" onClick={() => this.onClickUpdateNotificationStatus(data.title,data.message,data.urlPath,data.type,this.publishDateformat(data.createdDate))}>
                    //   <img src={AnnouncementNotification} class="Notification_category_img" />
                    //   <div class="notification_category_Message">
                    //     <p class="notification_category_label">{data.type}
                    //       <span className="notification_details_Date">{this.dateformat(data.createdDate)}</span>

                    //     </p>
                    //     <div style={{ color: '#4e4e4e' }}>{data.title}</div>
                    //   </div>
                    // </div>
                    // :
                    null
                })
              }
            </div>
            :
            <div style={{ padding: 35 }}>
              <div className="tempModalTxt">You currently have no new Announcements & Notices. </div>
            </div>
        }
      </div>


      <Modal show={this.state.noticeModal} centered className="ChangPayModal paymentWalletModal" backdrop='static'>
        <Modal.Body style={{ maxHeight: '410px', overflowY: 'auto' }}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            style={{ flexWrap: 'nowrap' }}
          >
            <span class="Change-Payment-Method" style={{ lineHeight: '1.3' }}>{this.state.header}</span>
            <IconButton aria-label="close" onClick={() => this.setState({ noticeModal: false })} style={{ marginTop: '-13px' }}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <div>
            <Grid container>
              <div style={{ fontWeight: 'bold', marginTop: '5%', marginLeft: '3%' }}>{this.state.publishDate}</div>
            </Grid>
            <pre class="The-fellowship-incurs-additional-fees-related-to-p" style={{
              margin: '0px', padding: '15px', overflowX: 'auto',
              whiteSpace: "pre-wrap"
            }} dangerouslySetInnerHTML={{ __html: this.state.content }}>
            </pre>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: 'center' }}>
          <Button variant="contained" onClick={() => this.setState({ noticeModal: false })} style={{ marginRight: '15px' }} className="announcementFooterButton">
            OK
          </Button>
          <Button variant="contained" onClick={() => this.handleOk(null, this.state.URL, this.state.Type)} style={{ marginRight: '15px' }} className="announcementFooterButton">
            VIEW DETAILS
          </Button>
        </Modal.Footer>
      </Modal>



    </div>)
  }
  onClickUpdateNotificationStatus = (title, msg, url, type, date) => {
    this.setState({
      noticeModal: true,
      content: msg,
      header: title,
      publishDate: date,
      URL: url,
      Type: type
    })

  }
  handleOk = (id, url, type) => {
    // NavigateURL (id,url,type)
    if (url === "ProgramInformation") {
      window.location.href = '/MemberInformation'
    } if (url == 'Needs' || url == 'MyNeeds') {
      window.location.href = '/MemberNeeds'
    }
    if (url == "notices") {
      window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHS-Sharing-Program-Guidelines.pdf")
    }
    this.setState({ noticeModal: false })
  }

  getDateInUTC = (date, getInMillisecs) => {

    if (date) {

      let newDateTime = new Date(date)


      return new Date(newDateTime)

    }

    return date

  }



  dateformat(date) {
    const enrollmentDate = this.getDateInUTC(date, true)
    let nowdate = this.getDateInUTC(new Date(), true)

    var day = moment(enrollmentDate).format('DD')

    var mon = moment(enrollmentDate).format('MM')

    var year = moment(enrollmentDate).format('YYYY')

    var date = mon + "/" + day + "/" + year

    var todayDate = moment(nowdate).format('MM/DD/YYYY')
    let hr = moment(enrollmentDate).format('hh')

    if (date !== todayDate) {

      return moment(date).format('MMM DD, YYYY')

    } else {


      if (hr == '00') {
        return moment(enrollmentDate).format('m') + 'mins'
      }
      if (hr > 12) {
        return moment(enrollmentDate).format('hh:mm A')
      } else {
        return moment(enrollmentDate).format('hh:mm A')
      }

    }

  }
  publishDateformat(date) {
    const enrollmentDate = this.getDateInUTC(date, true)
    let nowdate = this.getDateInUTC(new Date(), true)

    var day = moment(enrollmentDate).format('DD')

    var mon = moment(enrollmentDate).format('MM')

    var year = moment(enrollmentDate).format('YYYY')

    var date = mon + "/" + day + "/" + year

    var todayDate = moment(nowdate).format('MM/DD/YYYY')
    let hr = moment(enrollmentDate).format('hh')



    return moment(date).format('MMMM DD, YYYY')



  }
}