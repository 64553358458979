import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import { Modal, Row, Container, Col, Button, Card } from 'react-bootstrap'
// import AccordionViewModal from './AccordionViewModal'
import './MemberNeeds.css'
import moment from "moment";
const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    padding:'0px',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);



const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#ffffff',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 120,
    paddingLeft:'5px',
    paddingRight:'5px',
    '&$expanded': {
      minHeight: 125,
    },


  },


  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);
export default class  SimpleAccordion extends Component{
  constructor(props){
    super(props)
    this.state={
      expanded: '',
      formatedData: myrowsjsondata,
      showModal:false,
      modalData:null,
      expansionPanelOpen :false
    }
    console.log("Accordion pros", this.state.formatedData);

    console.log("=========props data isss==========",this.props)
  }

  handleChange =  panel => (event, expanded) =>{
    console.log("expanded====",panel,expanded)
    this.setState({expanded:expanded ? panel : false})
}


  openModalInfo(dataIndex) {
    this.setState({
      showModal: true,
      modalData:this.state.formatedData[dataIndex]
    })
  }

  closeModalInfo() {
    this.setState({
      showModal: false
    })
  }
componentDidMount(){
  window.scrollTo(0, 0);
}
  //--- To get updated props on loadMore Btn click
  componentWillReceiveProps(nextProps) {
    this.setState({ formatedData: nextProps.data });
  }

   getDate(date){
    const isDateValid = moment(date)['_isValid'];
    if (isDateValid) {
        return moment(date).format('MMMM D, YYYY');
    }
    return date;
}

      numberWithCommas(x) {
          if(x){
            if(x.toString().startsWith('$')) {
              return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
              return '$' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          } else {
            return 'NA';
          }
      }

      floatconversion = (x) => {
          if(x){
            if(x.toString().startsWith('$')) {
              let substr = x.substr(1,x.length);
              return '$' + (parseFloat(substr).toFixed(2)).toString();
            } else {
              return x.toFixed(2);
            }
          } else {
            return undefined;
          }
      }

    render(){
      return (
        <div className="mobileTable">
          {
           this.props.data.map((data, index) => (

              <Accordion expanded={this.state.expanded === 'panel'+index}  onChange={this.handleChange('panel'+index)}>
                <AccordionSummary
                  //expandIcon={<KeyboardArrowRightIcon  style={{marginLeft:'-25px'}}/>}
                  expandIcon={<ExpandMoreIcon className="collapseIcon" onClick={() => {
                    this.setState({
                      expansionPanelOpen: !this.state.expansionPanelOpen
                    });
                  }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="panelSummary"
                >
              <Grid container >
                <Grid item xs={8} className="itemWrap">
                  <div className="Expense-Number221">
                  Expense Number
                      </div>
                  <div className="Expense-Number211">{data.bill_key}</div>
                </Grid>

                <Grid item xs={4} className="itemWrap">
                
                  {
                    data.chs_claim_status=="Paid" || data.chs_claim_status=="Denied"?
                    <span className={'status_button Audit'}>Final</span>
                    :
                    <span className={'status_button Logged'}>Processing</span>

                  }
                </Grid>

                <Grid item xs={12} className="itemWrap">
                  <div className="Expense-Number221">Date of Service</div>
                  <div className="Expense-Number211">{this.getDate(data.start_of_service_date)}</div>
                </Grid>


                <Grid item xs={12} className="itemWrap">
                  <div className="Expense-Number221">Member</div>
                  <div className="Expense-Number211">{data.first_name} {data.last_name}</div>

                </Grid>


                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">Servicing NPI#</div>
                  <div className="Expense-Number211">
                  {data.paid_provider_npi}
                  {/* {data.DateOfServiceVal} */}
                  </div>
                </Grid>

                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">Charged</div>
                  <div className="Expense-Number211">
                    ${data.charged_amount}
                   </div>

                </Grid>


              </Grid>
              </AccordionSummary>
                <AccordionDetails style={{paddingLeft:'26px'}}>

                <Grid container >
                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">Paid Date</div>
                  <div className="Expense-Number211">{data.date_paid}</div>
                </Grid>
                <Grid item xs={5} className="itemWrap">
                  <div className="Expense-Number221">Repricing Discount</div>
                  <div className="Expense-Number211">{data.repricing_amount}</div>
                </Grid>
                {/* {
                  data.MoreDetail.EOSLabel == "" || data.MoreDetail.EOSLabel == " "
                  ?null
                  : */}
                  <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221"> Ineligible Amount</div>
                  <div className="Expense-Number211" >{data.ineligible_amount}</div>

                  {/* <div className="Expense-Number211 view_number" onClick={()=>this.openModalInfo(index)}>VIEW</div> */}
                </Grid>
                 {/* } */}


                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">Non-Sharable Amount</div>
                  <div className="Expense-Number211">{data.nsa}</div>

                </Grid>
                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">Consultation Fee</div>
                  <div className="Expense-Number211">{data.consultation_fee}</div>

                </Grid>

                <Grid item xs={5} className="itemWrap">
                  <div className="Expense-Number221">Sharing Percentage</div>
                  <div className="Expense-Number211">{data.sharing_percentage}</div>
                </Grid>

              </Grid>
                </AccordionDetails>
              </Accordion>
           ))}
        </div>
      );
    }
}


const myrowsjsondata = [
  {
    member_id: '600016',
    bill_key: '20116',
    first_name: 'Ben',
    last_name: 'Cutting',
    date_paid: 'None',
    date_received: '2020-04-20',
    status: 'Denied',
    charged_amount: '0.00',
    paid_amount: '0.00',
    paid_provider_name: 'aa',
    servicing:'182682',
    RepricingDiscount:'300',
    ineligible_amount:'200',
    nsa:'80',
    consultantFee:'900',
    sharingPercentage:'350',
  },
  {
    member_id: '600017',
    bill_key: '2046',
    first_name: 'Selina',
    last_name: 'Smith',
    date_paid: '2020-05-20',
    date_received: '2020-04-21',
    status: 'Denied',
    charged_amount: '100.00',
    paid_amount: '10.00',
    paid_provider_name: 'bb',
    servicing:'192682',
    RepricingDiscount:'0',
    ineligible_amount:'250',
    nsa:'100',
    consultantFee:'300',
    sharingPercentage:'350',
  },
  {
    member_id: '600018',
    bill_key: '20118',
    first_name: 'Jack',
    last_name: 'Mamba',
    date_paid: 'None',
    date_received: '2020-04-22',
    status: 'FINAL',
    charged_amount: '200.00',
    paid_amount: '20.00',
    paid_provider_name: 'cc',
    servicing:'166862',
    RepricingDiscount:'100',
    ineligible_amount:'150',
    nsa:'900',
    consultantFee:'60',
    sharingPercentage:'350',
  },
  {
    member_id: '600019',
    bill_key: '2053',
    first_name: 'David',
    last_name: 'Miller',
    date_paid: '2020-05-20',
    date_received: '2020-04-23',
    status: 'Denied',
    charged_amount: '300.00',
    paid_amount: '30.00',
    paid_provider_name: 'VITALCARE FP LLC',
    servicing:'802682',
    RepricingDiscount:'100',
    ineligible_amount:'150',
    nsa:'450',
    consultantFee:'80',
    sharingPercentage:'350',
  },
  {
    member_id: '600018',
    bill_key: '201337',
    first_name: 'Cane',
    last_name: 'Willimsom',
    date_paid: 'None',
    date_received: '2020-04-24',
    status: 'IN REVIEW',
    charged_amount: '400.00',
    paid_amount: '40.00',
    paid_provider_name: 'VITALCARE FP LLC',
    servicing:'172682',
    ineligible_amount:'80',
    nsa:'890',
    RepricingDiscount:'60',
    consultantFee:'990',
    sharingPercentage:'350',
  },
  {
    member_id: '600019',
    bill_key: '201857',
    first_name: 'Jonny',
    last_name: 'Stoke',
    date_paid: '2020-05-20',
    date_received: '2020-04-25',
    status: 'IN REVIEW',
    charged_amount: '0.00',
    paid_amount: '0.00',
    paid_provider_name: 'VITALCARE FP LLC',
    servicing:'282682',
    nsa:'60',
    ineligible_amount:'208',
    RepricingDiscount:'690',
    consultantFee:'500',
    sharingPercentage:'350',
  }
  
 ]


const sampleData2 = [
  {id: 733, planId: "7006", idcardField: "Telemedicine", fieldValue: "$0"},
  {id: 735, planId: "7006", idcardField: "PCP Office Visit", fieldValue: "$35"},
  {id: 736, planId: "7006", idcardField: "Urgent Care", fieldValue: "$100" },
  {id: 737, planId: "7006", idcardField: "ER Visit", fieldValue: "$400" }
];
