import { Component } from 'react';
import { withRouter } from 'react-router';

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }

  }
  componentDidMount() {

  }

  render() {
    return (
      <div class="footerContainer">
        <div class="webfooter">
          <div></div>
          <div>Copyright © 2021 Universal Health Fellowship. All rights reserved. | Powered by CarynHealth</div>
          <div>Version 1.0.0</div>
        </div>
      </div>

    )
  }


}
export default withRouter(Footer);