import { Component } from 'react';
import Header from '../Header/Header';
import { Grid } from "@material-ui/core";
import { Form } from "react-bootstrap";
import { Fab } from '@material-ui/core';
import { Modal, Row, Container, Col, Card } from 'react-bootstrap';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import './MemberInformation.css';
import IDCard from './IDcard';
import axios from 'axios';
import Loader from "../Styles/Loader";
import IDCardBack from './IDCardBack';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ModalIDcardFront from './ModalIDcard/ModalIDcardFront';
import ModalIDCardBack from './ModalIDcard/ModalIDcardBack';
import Footer from '../Footer/Footer'
import ComponentCommonBottom from '../ComponentFooter/ComponentCommonBottom';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';

export default class MemberInformation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ModalFrontIDcard: false,
      ModalBackIDcard: false,
      memberID: '',
      memberName: '',
      ShowMember: false,
      message: 'Enter the full name and membership ID of the member to view program information and eligibility. The entered details must match our records.',
      programInformation: {},
      programname: '',
      programname2: '',
      loader: false,
      idCardData: "",
      network: '',
      planInfo: '',
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  SearchMember = (e) => {
    if (e)
      e.preventDefault();
    this.setState({ loader: true, idCardData:"", network:"",programname2:'' })
    axios.get(process.env.REACT_APP_BASE_URL+`v12/providerportal/getMemberInfo/${this.state.memberID.replace(/[^\d.-]/g, '')}`).then(resp => {
      if ((resp.data.response.firstName.toUpperCase() + " " + resp.data.response.lastName.toUpperCase()) === this.state.memberName.toUpperCase() || ((resp.data.response.lastName.toUpperCase() + " " + resp.data.response.firstName.toUpperCase()) === this.state.memberName.toUpperCase())) {
        axios.get(process.env.REACT_APP_BASE_URL+`v7/memberportal/getProgramInformation/${resp.data.response.email}`)
          .then((response) => {
            this.setState({ programInformation: response.data });
            var str = response.data.programInfo.programName
            if (str.includes("+")) {
              var str1 = response.data.programInfo.programName;
              var res1 = str1.split(" ");
              this.setState({ programname: res1[0], programname2: " with " + res1[2], showwithacsm: true, ShowMember: true, })
            } else {
              this.setState({ programname: response.data.programInfo.programName, showwithacsm: false, ShowMember: true })
            }

            let user = {
              "email": resp.data.response.email
            }
            axios.post(process.env.REACT_APP_BASE_URL+"v2/memberportal/idcard", user, { headers: { Authorization: 'Bearer ' + localStorage.getItem('bearerToken') } }).then(data => {
              this.setState({ idCardData: data.data })
              console.log(data.data)
              axios.get(process.env.REACT_APP_BASE_URL+`v7/memberportal/getProviderNetwork/${data.data.memberIdCardList[0].planId}`).then(res => {
                this.setState({ loader: false, network: res.data.provider_network })
              })
            }).catch(err => {
              let request = {};
                if (process.env.REACT_APP_BUILD == 'prod') {
                 request = {
                    "username": "admin",
                    "password": "x1TXVUtXL6PaBWam"
                  }
                }else{
                  request = {
                      "username": "admin",
                      "password": "testpass"
                  }
                }
            axios.post(process.env.REACT_APP_BASE_URL+"v1/login", request).then(data=>{
                let bearer = data['headers'].authorization
                var array = bearer.split("Bearer ")
                localStorage.setItem("bearerToken", array[1]); 
                this.SearchMember();
            })
            })
          })
      } else {
        this.setState({ message: "The member name you entered does not match with our records.", ShowMember: false, loader: false });
      }
    }).catch(err => {
      this.setState({ message: "The membership ID you entered does not exist in our system.", ShowMember: false, loader: false })
    })
  }
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
  render() {
    return (<div>
      {this.state.loader ? <Loader /> : null}
      <Header header="Member Information" username="John Doe" />
      <div className="progInfoContainer container-fluid" style={{ zIndex: '0' }}>
        <div className="Back_page" onClick={() => this.goBack()}><ArrowBackIcon style={{ width: '24px', height: '24px', color: ' #543379', marginRight: '5px' }} onClick={() => this.goBack()} />BACK</div>
        <div className="commonWrap">

          <div className="progInfoSection">
            <div className="row">
              <div className="col-md-6">
                <h1 className="progInfoTitle">MEMBER Information</h1>
              </div>
              <div className="col-md-6 txtAlgnRight">
                <form onSubmit={(e) => this.SearchMember(e)}>
                  <TextField
                    label="Member ID*"
                    id="Member ID*"
                    className="inputStyle"
                    value={this.state.memberID}
                    style={{ marginLeft: '0',marginBottom: '10px' }}
                    onChange={(e) => this.setState({ memberID: e.target.value })}
                  />
                  <TextField
                    label="Member Name*"
                    id="Member Name*"
                    className="inputStyle"
                    value={this.state.memberName}
                    onChange={(e) => this.setState({ memberName: e.target.value })}
                  />
                  <Fab type="submit" style={{ backgroundColor: "#fec465", width: 43, height: 43, marginLeft: 20, boxShadow: 'none' }} aria-label="add" onClick={() => this.SearchMember(false)}>
                    <SearchIcon style={{ color: "#fff" }} />
                  </Fab>
                </form>
              </div>
            </div>
          </div>
          <div className="line"></div>
          <div className="tablebackgroundouter tablebackgroundouterPaymentWallet">
            {this.state.ShowMember ? <div className="Rectangle-271 row rowMargin">

              <div className="col-12 col-md-4 borderRight healthCardWrapper">
                <div className="frontViewWrapper">
                <Fab size="small" color="secondary" aria-label="add" className="zoomButton"
                  style={{
                    position: 'absolute',
                    top: '-15px',
                    right: '-15px',
                    backgroundColor: '#41b5c2',
                    zIndex: '2'
                  }} onClick={() => this.setState({ ModalFrontIDcard: true })}>
                  <ZoomInIcon />
                </Fab>
                  <div style={{marginBottom:'32px'}}>
                    {this.state.idCardData && <IDCard network={this.state.network} data={this.state.idCardData} />}
                  </div>
                </div>
                <div className="backViewWrapper">
                <Fab size="small" color="secondary" aria-label="add" className="zoomButton"
                  style={{
                    position: 'absolute',
                    top: '-17px',
                    right: '-17px',
                    backgroundColor: '#41b5c2',
                    zIndex: '2'
                  }} onClick={() => this.setState({ ModalBackIDcard: true })}>
                  <ZoomInIcon />
                </Fab>
                  {this.state.idCardData && <IDCardBack network={this.state.network} data={this.state.idCardData} />}
                </div>
              </div>
              {/* <div className="col-12 col-md-4 borderRight" style={{ background: "#eeeeee" }}>
                <Fab size="small" color="secondary" aria-label="add"
                  style={{
                    marginLeft: '85%',
                    marginBottom: '-39px',
                    backgroundColor: '#41b5c2',
                    zIndex: '9999'
                  }} onClick={() => this.setState({ ModalFrontIDcard: true })}>
                  <ZoomInIcon />
                </Fab>
                <div style={{ marginTop: 65 }}>
                  {this.state.idCardData && <IDCard network={this.state.network} data={this.state.idCardData} />}
                </div>

                <Fab size="small" color="secondary" aria-label="add"
                  style={{
                    marginLeft: '85%',
                    marginBottom: '-39px',
                    backgroundColor: '#41b5c2',
                    zIndex: '9999'
                  }} onClick={() => this.setState({ ModalBackIDcard: true })}>
                  <ZoomInIcon />
                </Fab>
                <div style={{ marginTop: 65 }}>
                  {this.state.idCardData && <IDCardBack network={this.state.network} data={this.state.idCardData} />}
                </div>

              </div> */}

              <div className="col-12 col-md-8 programInfoWrapper">
                <div className="row">
                  <div className="col-12 col-md-12">
                    <div className="client"><h1>{this.state.programname}</h1> <h2>{this.state.programname2}</h2></div>
                  </div>
                  <div className="col-12 col-md-5">
                    <h6 className="infoHeading">Summary</h6>

                    <div className="progessBarWrapper">
                      <div className="barTitle">Non-Sharable amount</div>
                      <div className="progressContainer">
                        <div className="progressIndicator" style={{ width: this.state.programInformation.nsa.met / this.state.programInformation.nsa.remaining * 100 }}><ArrowRightAltIcon viewBox="0 6 24 24" style={{ color: '#ffffff' }} /></div>
                      </div>

                      <div className="amtWrapper">
                        <span className="metAmt">${this.numberWithCommas(this.state.programInformation.nsa.met)} met</span>
                        <span className="remainingAmt">${this.numberWithCommas(this.state.programInformation.nsa.remaining)} remaining</span>
                      </div>
                    </div>
                    {this.state.programname2 &&
                      <div className="progessBarWrapper">
                        <div className="barTitle">Annual Co-Share Maximum Amount</div>
                        <div className="progressContainerYellow">
                          <div className="progressIndicatorYellow" style={{ width: this.state.programInformation.acsm.met / this.state.programInformation.acsm.remaining * 100 }}><ArrowRightAltIcon viewBox="0 6 24 24" style={{ color: '#ffffff' }} /></div>
                        </div>

                        <div className="amtWrapper">
                          <span className="metAmt metAmtYellow">${this.numberWithCommas(this.state.programInformation.acsm.met)} met</span>
                          <span className="remainingAmt">${this.numberWithCommas(this.state.programInformation.acsm.remaining)} remaining</span>
                        </div>
                      </div>}

                  </div>
                  <div className="col-12 col-md-7">
                    <div className="lineBreak"></div>
                    <div className="leftData">
                      <div className="row">

                        <div className="col-12 col-md-5">
                          <h6 className="infoHeading">Eligible Services</h6>
                          <div className="row">
                            {this.state.programInformation.planInfo.map((response) => (
                              <div className="col-6 col-md-12" style={{ marginBottom: '20px' }}>
                                <p className="label" dangerouslySetInnerHTML={{ __html: response.idcardField}}></p>
                                <p className="number" dangerouslySetInnerHTML={{ __html: response.fieldValue}}></p>
                              </div>))
                            }
                          </div>
                        </div>
                        <div className="col-12 col-md-7">
                          <h6 className="infoHeading">Sharing Limits</h6>
                          {this.state.programInformation.expenseLimits.map((res) => (
                            <div className="row" style={{ paddingBottom: 14 }}>
                              <p className="label" dangerouslySetInnerHTML={{ __html: res.idcardField}}></p>
                              <p className="number" dangerouslySetInnerHTML={{ __html: res.fieldValue}}></p>
                            </div>))}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              {/* <div className="col-12 col-md-4" style={{ marginTop: 10, padding: 29 }}>


              </div>

              <div className="col-12 col-md-4">

              </div> */}

            </div> :
              <div className="row rowMargin">
                <div className="msgWrapper">
                  <div className="row">
                    <div className="col-2 col-md-1 text-center">
                      <ErrorIcon style={{ color: "#fff", fontSize: '40px' }} />
                    </div>
                    <div className="col-10 col-md-11 text-left msgVertCenter">
                      <h6>{this.state.message}</h6>
                    </div>
                    {/* <div className="col-md-1 text-center">
                    <CloseIcon style={{ color: "#fff", fontSize:'20px' }} />
                    </div> */}
                  </div>
                </div>
              </div>
            }

          </div>

          <ComponentCommonBottom></ComponentCommonBottom>
        </div>
      </div>
      <Footer></Footer>
      <Modal className="frontCardModal" show={this.state.ModalFrontIDcard} centered onHide={() => this.setState({ ModalFrontIDcard: false })} style={{ overflowY: 'hidden' }} width="30%" animation="fadeInUp">

        {this.state.idCardData && <IDCard network={this.state.network} data={this.state.idCardData} />}
      </Modal>

      <Modal className="backCardModal" show={this.state.ModalBackIDcard} centered onHide={() => this.setState({ ModalBackIDcard: false })} style={{ overflowY: 'hidden' }} width="30%" animation="fadeInUp">
      {this.state.idCardData && <IDCardBack network={this.state.network} data={this.state.idCardData} />}
        {/* <ModalIDCardBack network={this.state.network} data={this.state.idCardData} /> */}
      </Modal>
    </div>)
  }
  goBack = () => {
    this.props.history.push('/')
  }
}