import { Component } from 'react';
import ThankLogo from '../Image/ThankYouSharing.png';
import SharingLogo from '../Image/sharing.png';
import {Grid} from '@material-ui/core'
import './IDcardModal.css';

export default class ModalIDCardBack extends Component{
    constructor(props) {
      super(props)
      this.state = {
        contactNumber:"",
      }
    }
    componentDidMount() {
      this.props.data.memberIdCardList[0].planInfo.map((info, index) => {
        if (info.idcardField == "contact number") {
          this.setState({ contactNumber: info.fieldValue })
        }
      })
    }
render(){
        return (
        <div className="fviewModal">
           <Grid container>
              <Grid item md={12}>
                <img src={ThankLogo} class="THANK-YOU-FORModal"/>
              </Grid>
            <div style={{padding: '5px'}}>
            <Grid item md={12}>
            {/* <span class="ForPreventModal">
                For Preventive Services Appointments visit PreventiveServices.UniversalHealthFellowship.org<br/>
                For Customer Service call: (xxx) xxx-xxxx
                To find a provider visit FindProvider.UniversalHealthFellowship.org
                Providers, send needs to: PO Box 211223, Eagon, MN 55121</span> */}
                {(() => {
            switch (this.props.network) {
              case 'PHCS':
                return (
              
                  <span class="ForPreventModal">For Preventive Services Appointments visit: PreventiveServices.UniversalHealthFellowship.org
                   <br/>For Customer Service call: {this.state.contactNumber}<br/>
                       To find a provider visit FindProvider.UniversalHealthFellowship.org
                  </span>
               
                )
                case 'healthyLife':
                  return (
                    <span class="ForPreventModal">
                      
                        For Preventive Services Appointments visit: PreventiveServices.UniversalHealthFellowship.org
                        <br/>For Customer Service call: {this.state.contactNumber}<br/>
                           To find a provider visit FindProvider.UniversalHealthFellowship.org
                       EDI #40585 
                      </span>
                  )
                  case 'smartshare25':
                    return (
                      <span class="ForPreventModal">
                    
                          For Preventive Services Appointments visit: PreventiveServices.UniversalHealthFellowship.org
                          <br/>For Customer Service call: {this.state.contactNumber}<br/>
                          To find a provider visit FindProvider.UniversalHealthFellowship.org
                            EDI #40585
                      </span>
                    )
                case 'smartshare50':
                  return (
                    <span class="ForPreventModal">
                        For Preventive Services Appointments visit: PreventiveServices.UniversalHealthFellowship.org
                        <br/>For Customer Service call: {this.state.contactNumber}<br/>
                          To find a provider visit FindProvider.UniversalHealthFellowship.org
                      EDI #40585 
                    </span>
                  )
              case 'Smartshare':
                return (
                  <span class="ForPreventModal">
                  
                      For Preventive Services Appointments visit: PreventiveServices.UniversalHealthFellowship.org
                      <br/>For Customer Service call: {this.state.contactNumber}<br/>
                      To find a provider visit FindProvider.UniversalHealthFellowship.org
                        
                  </span>
                )

              case 'AFMC':
                return (
                  <span class="ForPreventModal">
                    For Preventive Services Appointments visit: PreventiveServices.UniversalHealthFellowship.org
                    <br/>For Customer Service call: {this.state.contactNumber}<br/>
                    To find a provider visit https://azfmc.com/providersearch
                      
                  </span>
                )
            }
          })()}
              </Grid>
            </div>
            <div style={{padding: '0 17px'}}>
            <Grid item md={12}>
            {(() => {
            switch (this.props.network) {
              case 'PHCS':
                return (
                  <p class="textBAckModal">
                   Providers, send needs to: P.O. Box 211223, Eagon, MN 55121
                      Pre-notification is required before these procedures will be eligible for sharing: All Inpatient Hospital
                      Confinements, All Surgical Procedures (Inpatient, Outpatient and Ambulatory, Organ and Tissue Transplant Services)
                      Cancer Treatment and Oncology Services, Independent Lab Tests and Imaging, Home Health Care Services, Carpal Tunnel
                      Treatments. In addition, Pre-certification to confirm medical necessity is required before these procedures may be
                      eligible for sharing: Transplant of any organ or tissue, a coronary bypass or graft of any kind, or a knee or hip
                      replacement. For Medical Emergencies Seek Immediate Medical Help.
                   
                      Participating member assignment of eligible medical expense sharing payment is permitted as consideration in full for
                      services rendered. Reimbursement for hospital facility services is determined at a percentage of the facility’s
                      Medicare allowable amounts (140% for Inpatient and 155% for Outpatient Services), or, in the absence of an applicable
                      CMS fee schedule, in accordance with published UHF Sharing Guidelines. Acceptance of sharing payment for Eligible
                      Expenses constitutes waiver of facility/provider right to balance bill patient.
                    
                      See applicable Sharing Guidelines for more details.{' '}
                      Universal Health Fellowship is a
                      not-for-profit ministry that facilitates medical expense sharing through Universal HealthShare Programs. Universal
                      HealthShare is not insurance and does not guarantee that eligible medical bills will be shared or otherwise paid.
                      Universal HealthShare is not a discount card or program.
                    
                  </p>
                )

                case 'healthyLife':
                  return (
                    <p class="textBAckModal">
                    Providers, send needs requests to: PO Box 106 Rutherford, NJ 07070-0106
                        Pre-notification is required before these procedures will be eligible for sharing: All Inpatient Hospital
                        Confinements, All Surgical Procedures (Inpatient, Outpatient and Ambulatory, Organ and Tissue Transplant Services)
                        Cancer Treatment and Oncology Services, Independent Lab Tests and Imaging, Home Health Care Services, Carpal Tunnel
                        Treatments. In addition, Pre-certification to confirm medical necessity is required before these procedures may be
                        eligible for sharing: Transplant of any organ or tissue, a coronary bypass or graft of any kind, or a knee or hip
                        replacement. For Medical Emergencies Seek Immediate Medical Help.
                  
                        Participating member assignment of eligible medical expense sharing payment is permitted as consideration in full for
                        services rendered. Reimbursement for hospital facility services is determined at a percentage of the facility’s
                        Medicare allowable amounts (140% for Inpatient and 155% for Outpatient Services), or, in the absence of an applicable
                        CMS fee schedule, in accordance with published UHF Sharing Guidelines. Acceptance of sharing payment for Eligible
                        Expenses constitutes waiver of facility/provider right to balance bill patient.
                  
                        See applicable Sharing Guidelines for more details.{' '}
                        Universal Health Fellowship is a
                        not-for-profit ministry that facilitates medical expense sharing through Universal HealthShare Programs. Universal
                        HealthShare is not insurance and does not guarantee that eligible medical bills will be shared or otherwise paid.
                        Universal HealthShare is not a discount card or program.
                    </p>
                  )
  
                case 'smartshare25':
                  return (
                    <p class="textBAckModal">
                  Providers, send needs requests to: PO Box 106 Rutherford, NJ 07070-0106
                      All medical services for the Healthy Life EasyShare Program 25 are limited
  to an annual maximum of $25,000 per member.
                      Member assignment to Providers of eligible medical expense sharing reimbursement as consideration for services
                            rendered is permitted by Universal Health Fellowship (UHF). Sharing reimbursement for eligible hospital or
                            ambulatory surgical center services/expenses is determined at a percentage of the facility’s Medicare allowable
                            amounts (140% for Inpatient and 155% for Outpatient Services), or, absent an applicable CMS fee schedule, in
                            accordance with current published UHF Sharing Guidelines. See applicable Sharing Guidelines for details.
                    
                      See applicable Sharing Guidelines for details.{' '}
                        Universal Health Fellowship (UHF).  is a not-for-profit ministry that facilitates medical expense sharing through Universal HealthShare Programs.
                            Universal HealthShare is not insurance and does not guarantee that eligible medical bills will be shared or
                            otherwise paid. Universal HealthShare is not a discount card or program.
                      
                    </p>
                  )
                  case 'smartshare50':
                  return (
                    <p class="textBAckModal">
  Providers, send needs requests to: PO Box 106 Rutherford, NJ 07070-0106
                      All medical services for the Healthy Life EasyShare Program 50 are limited
  to an annual maximum of $50,000 per member.
                     
                      Member assignment to Providers of eligible medical expense sharing reimbursement as consideration for services
                            rendered is permitted by Universal Health Fellowship (UHF). Sharing reimbursement for eligible hospital or
                            ambulatory surgical center services/expenses is determined at a percentage of the facility’s Medicare allowable
                            amounts (140% for Inpatient and 155% for Outpatient Services), or, absent an applicable CMS fee schedule, in
                            accordance with current published UHF Sharing Guidelines. See applicable Sharing Guidelines for details.

                      See applicable Sharing Guidelines for details.{' '}
                      Universal Health Fellowship (UHF). is a not-for-profit ministry that facilitates medical expense sharing through Universal HealthShare Programs.
                            Universal HealthShare is not insurance and does not guarantee that eligible medical bills will be shared or
                            otherwise paid. Universal HealthShare is not a discount card or program.
                  
                    </p>
                  )
  

              case 'Smartshare':
                return (
                  <p class="textBAckModal">
                   Providers, send needs requests to: P.O. Box 211223, Eagan, MN 55121
                    
                      All medical services for the UHS SmartShare Program are limited to an annual maximum of $27,500 per member.
            
                    Member assignment to Providers of eligible medical expense sharing reimbursement as consideration for services
                          rendered is permitted by Universal Health Fellowship (UHF). Sharing reimbursement for eligible hospital or
                          ambulatory surgical center services/expenses is determined at a percentage of the facility’s Medicare allowable
                          amounts (140% for Inpatient and 155% for Outpatient Services), or, absent an applicable CMS fee schedule, in
                          accordance with current published UHF Sharing Guidelines. See applicable Sharing Guidelines for details.
                
                    See applicable Sharing Guidelines for details.{' '}
                      Universal Health Fellowship (UHF).   is a not-for-profit ministry that facilitates medical expense sharing through Universal HealthShare Programs.
                          Universal HealthShare is not insurance and does not guarantee that eligible medical bills will be shared or
                          otherwise paid. Universal HealthShare is not a discount card or program.
                  
                  </p>
                )

              case 'AFMC':
                return(
                  <p class="textBAckModal">
                    Providers, send needs requests to: Arizona Foundation, P.O. Box 2909, Phoenix, AZ 85062-2909
                Pre-notification is required before these procedures will be eligible for sharing: All Inpatient Hospital
                          Confinements, All Surgical Procedures (Inpatient, Outpatient and Ambulatory, Organ and Tissue Transplant Services)
                          Cancer Treatment and Oncology Services, Independent Lab Tests and Imaging, Home Health Care Services, Carpal
                          Tunnel Treatments. In addition, Pre-certification to confirm medical necessity is required before these procedures
                          may be eligible for sharing: Transplant of any organ or tissue, a coronary bypass or graft of any kind, or a knee
                          or hip replacement. For Medical Emergencies Seek Immediate Medical Help.
                Participating member assignment of eligible medical expense sharing payment is permitted as consideration in full
                          for services rendered. Reimbursement for hospital facility services is determined at a percentage of the
                          facility’s Medicare allowable amounts (140% for Inpatient and 155% for Outpatient Services), or, in the absence of
                          an applicable CMS fee schedule, in accordance with published UHF Sharing Guidelines. Acceptance of sharing payment
                          for Eligible Expenses constitutes waiver of facility/provider right to balance bill patient.
            
                See applicable Sharing Guidelines for more details.{' '}
                 Universal Health Fellowship (www.UniversalHealthFellowship.org)   is a
                          not-for-profit ministry that facilitates medical expense sharing through Universal HealthShare Programs. Universal
                          HealthShare is not insurance and does not guarantee that eligible medical bills will be shared or otherwise paid.
                          Universal HealthShare is not a discount card or program.  
                          {/* <span class="azmc_id_card_no_bottonm">AFMC EDI #86062</span> */}
                </p>
                )
            }
          })()}
            </Grid>
            </div>
            <Grid item md={12}>
            {this.props.data && this.props.data.memberIdCardList[0].planInfo.map(res=>(res.idcardField === "card id"?<span class="bottomnumberModal">{res.fieldValue}</span>:null))}
           
            </Grid>
            </Grid>
          </div>)
    }
}