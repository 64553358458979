import React, { Component } from 'react';
import SimpleAccordion from './myneedsAccordion';
import Sidebar from 'react-sidebar';
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import customStyle from '../Styles/stylesheet_UHS';
import Header from '../Header/Header';
import { Grid } from "@material-ui/core";
import { Form } from "react-bootstrap";
import { Fab } from '@material-ui/core';
import { Modal, Row, Container, Col, Card } from 'react-bootstrap';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import DataTable from './DataTable';
import AskBOmba from '../../Assets/carynIcon.jpg';
import axios from 'axios';
import Loader from "../Styles/Loader";
import Footer from '../Footer/Footer';
import { Auth } from 'aws-amplify';
import ComponentCommonBottom from '../ComponentFooter/ComponentCommonBottom';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';

export default class MemberNeeds extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ModalFrontIDcard: false,
      ModalBackIDcard: false,
      loader: true,
      needsData: '',
      Servicing: '',
      message: ""
    }
    this.myRef = React.createRef()
  }
  componentDidMount = async () => {
    this.myRef.current.scrollTo(0, 0);
    const currentUserInfo = await Auth.currentUserInfo()
    var ServicingNPI = currentUserInfo.attributes['custom:ServicingNPI']
    this.setState({ loader: true, servicingNPI: ServicingNPI});
    axios.get(process.env.REACT_APP_NEEDS_URL+`needs-report?sort_type=asc&sort_field=start_of_service_date&page=1&size=10&provider_npi=${ServicingNPI}`).then((response) => {
      if (response.data.length === 0) {
        this.setState({ message: "There are currently no member needs that have been submitted. For any clarification, please contact customer service.", needsData: "", loader: false })
      } else {
        this.setState({ needsData: response.data, message: "", loader: false })
      }
    }).catch(err=>{
      this.setState({ message: "There are currently no member needs that have been submitted. For any clarification, please contact customer service.", needsData: "", loader: false })
    })
  }
  render() {
    return (<div ref={this.myRef}>
      {this.state.loader ? <Loader /> : null}
      <Header header="Member Needs" username="John Doe" />
      <div className="progInfoContainer container-fluid" style={{ zIndex: '0' }}>
        <div className="Back_page" onClick={() => this.goBack()}><ArrowBackIcon style={{ width: '24px', height: '24px', color: ' #543379', marginRight: '5px' }} onClick={() => this.goBack()} />BACK</div>
        <div className="commonWrap" >

          <div className="progInfoSection">
            <div className="col-md-12 row">
              <div className="col-md-6"><span className="progInfoTitle">MEMBER Needs</span></div>
              <div className="col-md-2"></div>

            </div>
          </div>
          <div className="line"></div>
          <div className="tablebackgroundouter tablebackgroundouterPaymentWallet">
            <div className=".Rectangle-271needs row">
              {this.state.needsData ? <>
                <div className="desktopTable">
                  {this.state.needsData && <DataTable data={this.state.needsData} handleRow={(val)=>this.handleRow(val)} />}
                </div>
                <div className="mobileTable">
                  {this.state.needsData && <SimpleAccordion data={this.state.needsData} />}
                </div>
              </> :
                // <div style={{ height: "50vh" }}>
                //   <h3 style={{ marginTop: 50, textAlign: 'center' }}>{this.state.message}</h3>
                // </div>
                this.state.message &&
                <div className="row rowMargin">
                <div className="msgWrapper">
                  <div className="row">
                    <div className="col-md-1 text-center">
                      <ErrorIcon style={{ color: "#fff", fontSize:'40px' }} />
                    </div>
                    <div className="col-md-11 text-left msgVertCenter">
                      <h6>{this.state.message}</h6>
                    </div>
                  </div>
                </div>                
              </div>
              }
            </div>

          </div>

          <ComponentCommonBottom></ComponentCommonBottom>
        </div>
      </div>
      <Footer></Footer>
    </div>)
  }
  handleRow=(value)=>{
    this.setState({ loader: true});
    axios.get(process.env.REACT_APP_NEEDS_URL+`needs-report?sort_type=asc&sort_field=start_of_service_date&page=1&size=${value}&provider_npi=${this.state.servicingNPI}`).then((response) => {
    if (response.data.length === 0) {
        this.setState({ message: "There are currently no member needs that have been submitted. For any clarification, please contact customer service.", needsData: "", loader: false })
      } else {
        this.setState({ needsData: response.data, message: "", loader: false })
      }
    }).catch(err=>{
      this.setState({ message: "There are currently no member needs that have been submitted. For any clarification, please contact customer service.", needsData: "", loader: false })
    })
  }
  SearchMember = (e) => {
    if (e)
      e.preventDefault();
  }
  goBack = () => {
    this.props.history.push('/')
  }
}