import React from "react";
import Home from './Components/Dashboard/Home';
import MemberInformation from './Components/MemberInformation/MemberInformation';
import ChatWindow from './Components/ChatBox/ChatWindow'
import { BrowserRouter, Link, Route , Redirect, Switch} from 'react-router-dom';
import MemberNeeds from "./Components/MemberNeeds/MemberNeeds";
import PaymentWallet from "./Components/PaymentWallet/PaymentWallet";
import { useEffect, useState } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import MyNotificationScreen from './Components/MyNotification/MyNotificationScreen';
import Announcements from './Components/Notices/Anouncement';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

function Routes() {
    useEffect(() => {
        Auth.currentSession().then((session) => {
          localStorage.setItem("isLogin", true);
        }).catch((error) => {
          localStorage.setItem('isLogin', false)
          window.location.reload();
        })
      },[])
    return(<div style={{backgroundColor:'#EAE8DB'}}>
        <BrowserRouter>
            <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/MemberInformation" component={MemberInformation} />
                  <Route exact path="/MemberNeeds" component={MemberNeeds} />
                  <Route exact path="/AiAssistant" component={ChatWindow} />
                  <Route exact path="/PaymentWallet" component={PaymentWallet} />
                  <Route exact path="/MyNotification" component={MyNotificationScreen} />
                  <Route exact path="/Announcements" component={Announcements} />
                  <Redirect from="/" to="/" />
            </Switch>
        </BrowserRouter>
    </div>)
 
  }
  
  export default Routes;