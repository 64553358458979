import { Component } from 'react';
import { withRouter } from 'react-router';
import { Form } from "react-bootstrap";
import { Fab } from '@material-ui/core';
import AskBOmba from '../../Assets/carynIcon.jpg';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import carynIcon from '../../Assets/carynIcon.jpg';
import CryptoJS from "crypto-js";

class ComponentCommonBottom extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }

  }
  componentDidMount() {

  }

  openAskCaryn=()=>{
    let urlData={
        "memberId": localStorage.getItem('Member_EMPID'),
        "query": this.state.documentmessage,
        "ChannelUId":"memberportal",
      }
    let key = CryptoJS.enc.Utf8.parse("000102030405060708090a0b0c0d0e0f");
    let iv = CryptoJS.enc.Utf8.parse("4t7w9z$C&F)J@NcR");
    let input = CryptoJS.enc.Utf8.parse(JSON.stringify(urlData));
    var dataEncrypt = CryptoJS.AES.encrypt(input, key, { keySize: 256 / 32, iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }).toString();
    window.open(`https://inf-labs.com/?isnav=true&data=${encodeURIComponent(dataEncrypt)}`)
}

  render() {
    return (
      <div className="progInfoFooter">
        <div className="row">
          <div className="col-md-8" >
            <div style={{ paddingTop: 18, paddingLeft: 20, justifyContent: 'space-between', display: 'flex' }}>
              {/* <Form.Control value={this.state.documentmessage} placeholder="Find out member's eligibility for specific health needs..." className="inputBox searchInutBox" onChange={e => this.setState({ documentmessage: e.target.value })} />
              <Fab style={{ backgroundColor: "#543379", width: 42, height: 39, right: 32 }} onClick={() => this.openAskCaryn()}>
                <img src={carynIcon} width="40px" />
              </Fab> */}
            </div>
          </div>
          <div className="col-md-4 dpFlex">
            {/* <div className="elevation"></div> */}
            <div className="footerText" style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                <p>Need help?</p>
                <p>Chat with our Health Share Representative</p>
                <p>or call 1 (888) 366 6243.</p>
              </div>
              <div style={{ marginLeft: 12 }}>
                <Fab color="#fb6647" style={{ backgroundColor: "#fb6647" }} aria-label="add" className={'purechat-button-expand'} color="primary" aria-label="add"> {/*this.props.history.push('/AiAssistant')*/}
                  {/* <img width="50" src={AskBOmba} /> */}
                  <QuestionAnswerOutlinedIcon style={{ color: "#fff", fontSize: '30px' }} />
                </Fab>
              </div>
            </div>

          </div>

        </div>
      </div>
    )
  }


}
export default withRouter(ComponentCommonBottom);