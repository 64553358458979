import React from "react";
import welcomeImg from '../../Assets/main.jpg';
import uhsImg from '../../Assets/uhs.svg';
import Button from '@material-ui/core/Button';
import { Auth } from "aws-amplify";
import MenuIcon from '@material-ui/icons/Menu'
import axios from "axios";
import Loader from "../Styles/Loader";
import FirstPasswordChange from './FirstPasswordChange';
import ForgotPassword from "./ForgotPassword";

class Siginin extends React.Component{
constructor(props){
    super(props);
    this.state={
        isReveal:true,
        username:'',
        password:'',
        errorMsg:'',
        loader:false,
        firstTimepwdRest:false,
        user:"",
        forgotPassword:false,
        PleaseCheck:false,
    }
}
render(){
    return (<div class="loginmobile">
      {this.state.loader?<Loader/>:null}
        {/* <div class="loginmobile_header"> */}
          {/* <MenuIcon style={{ color: '#ffffff', marginRight: '4vw',width:'8vw' }} /> */}
          {/* <div class="loginmobile_header_text">Provider Portal</div>
        </div> */}

     <div className="loginform_containernew">
        <div className="login_image_container">
             <img src={welcomeImg} className="login_main_image"/>
             <img src={uhsImg} className="login_uhs_logo"/>
        </div>
        {this.loginScreen() }
     </div>
    </div>)
}
loginScreen=()=>{
  if(this.state.forgotPassword){
      return(
        <div>
          <ForgotPassword PleaseCheck={this.state.PleaseCheck} gotoLoginScreen={()=>this.setState({forgotPassword:false})}/>
        </div>
      )
  }else if(this.state.firstTimepwdRest)
    return(<div>
    <FirstPasswordChange user={this.state.user}/>
    </div>)
  else
  return(<div>
    {this.state.errorMsg && <p className="login_new_error_text">{this.state.errorMsg}</p>}
    <div className="login_input_container">
            
              <input
              type="text"
              placeholder="ENTER YOUR EMAIL"
              name="username"
              value={this.state.username}
              onChange={(e) => this.setState({username:e.target.value})}
              required
              className="login_input_username"
            />
       
             <input
              className="login_input_username"
              id="pass"
              type="password"
              value={this.state.password}
              onChange={(e) => this.setState({password:e.target.value})}
              placeholder="ENTER YOUR PASSWORD"
              name="password"
            //   ref={this.passwordInput}
              required
            //   onChange={this.handleChange.bind(this)}
              onKeyDown={this.handleKeyUp}
            />
            <span className="p-viewer" onClick={() => this.togglePassword('pass')}>
            {this.state.isReveal ?
            <i className="fa fa-eye-slash" aria-hidden="true" ></i>:
              <i className="fa fa-eye" aria-hidden="true" ></i> 
            }
            </span>
            <Button
              type="submit"
              class="login_button"
              onClick={() => this.Siginin()}
            > SIGN IN</Button>
    </div>
    <div className="forgot_text-margin">
            <div className="loginmobile_forgot_text" onClick={()=>this.setState({forgotPassword:true, PleaseCheck:false})}>Forgot your password?
            </div>
    </div>

    <div className="login_bottom_text">
            <div className="login_bottom_subtext">Having trouble logging in?</div>
            <div className="login_bottom_subtext">
            
                <span className="login_plese_text" onClick={()=>this.setState({forgotPassword:true, PleaseCheck: true})}>Please check </span>
                 if your email has been registered on the portal.
            </div>
    </div>
    </div>)
}
Siginin= async ()=>{
  this.setState({loader:true})
  const authUser = await Auth.signIn({
    username: this.state.username.trim(),
    password: this.state.password.trim(),
  }).catch(err => {
    console.log("error", err.message)
    this.setState({
      errorMsg: err.message,
      disableSiginBtn: false,
      loader:false,
    })
  });
  console.log("authUser",authUser)
  localStorage.setItem("authUser", JSON.stringify(authUser));
  if (authUser  && authUser.challengeName == "NEW_PASSWORD_REQUIRED") {
    this.setState({
      user: authUser,
      loader:false,
      // disableSiginBtn: false,
      firstTimepwdRest: true,

    })
  }
  else {
    localStorage.setItem("isLogin", true);
    let request = {};
    if (process.env.REACT_APP_BUILD == 'prod') {
     request = {
        "username": "admin",
        "password": "x1TXVUtXL6PaBWam"
      }
    }else{
      request = {
          "username": "admin",
          "password": "testpass"
      }
    }
  
  axios.post(process.env.REACT_APP_BASE_URL+"v1/login", request).then(data=>{
    if (data.message) {
      console.log("failed to get token")
      alert(data.message)
  }
  else if(authUser) {
    this.saveUserNotificationDetails()
      let bearer = data['headers'].authorization
      var array = bearer.split("Bearer ")
      localStorage.setItem("bearerToken", array[1]);
  }
  })
  }
 
}

saveUserNotificationDetails = () => {
  let email=this.state.username.trim()
  let phone=""
  let username=this.state.username.trim()
  let firebase=localStorage.getItem('firebaseToken')
  let type=localStorage.getItem('TOKEN_TYPE')
  let client_id=""
  let obj={
    "email":email,
    "phone":phone,
    "username":username,
    "token":firebase,
    "type":type,
    "clientId":client_id
  }
  console.log("Main tsx saveUserObject=====",obj)
  axios.post(process.env.REACT_APP_BASE_URL+"v8/Twillio/" + 'saveUser',obj)
    .then(res=>{
      window.location.reload()
      console.log("seva user++++++++")
    }).catch(err=>{
      window.location.reload();
    })
}

handleKeyUp(e) {
    if (e.keyCode == 9) {
      e.preventDefault();
      // alert("Execute ajax call after tab pressed");
    }
  }
togglePassword=(id)=>{
    this.setState({isReveal:!this.state.isReveal})
    var pass=document.getElementById(id)
    if(pass.type=='password'){
      pass.type="text"
    }else{
      pass.type="password"

    }

  }
}
export default Siginin;