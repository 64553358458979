import { Component } from 'react';
import { Form } from "react-bootstrap";
import { Fab } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DocumentIcon from '../../Assets/documents-icon-active.svg';
import ProgramInfoIcon from '../../Assets/program-info-icon-active.svg';
import carynIcon from '../../Assets/carynIcon.jpg';
import CloseIcon from '@material-ui/icons/Close';
import CryptoJS from "crypto-js";
export default class ProviderDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            npiNumber:''
        }

    }

    componentDidMount() {
        var authUser = localStorage.getItem('authUser');
        var user = JSON.parse(authUser);
        var ServicingNPI = user.attributes['custom:ServicingNPI']
        var maskNpiNumber = ServicingNPI.replace(/\d(?=\d{4})/g, "*");
        this.setState({ npiNumber: maskNpiNumber })
    }

    render() {
        return (<div>
            <div className="cardwallet_back_notification documentsCard">
                <div className="NotificationCard-BG text-center">
                    <div className="cardwallet_onover_backdivMain">
                        <img src={ProgramInfoIcon} className="cardwaalet_img_backside" style={{ width: '30px', margin:'4px' }} />
                        <div className="notification_label_backside">Provider Information</div>
                        <div style={{ paddingLeft: 0 }} class="crossButton"> {this.props.modal ? <CloseIcon onClick={() => this.props.closeModal()} style={{ color: '#818181', cursor: "pointer" }} /> : <MoreVertIcon style={{ color: '#818181' }} />}</div>
                    </div>
                </div>

                <div class="providerDetailBg">
                    <h4>Practice:</h4>
                    <h2 style={{color:'#b4b4b4'}}>NA</h2>

                    <h4>NPI #:</h4>
                    <h2>{this.state.npiNumber}</h2>

                    <h4>Address:</h4>
                    <h2 style={{color:'#b4b4b4'}}>NA</h2>
                </div>
            </div>
        </div>)
    }

}

