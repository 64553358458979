
const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": process.env.REACT_APP_AWS_cognito_identity_pool_id,
    "aws_user_pools_id": process.env.REACT_APP_AWS_user_pools_id,
    "aws_user_pools_web_client_id": process.env.REACT_APP_AWS_user_pools_web_client_id,
    "oauth": {},


    // "aws_project_region": "us-east-2",
    // "aws_cognito_identity_pool_id": "us-east-2:ddba795b-ba54-4c1c-8d87-7c60c99fe940",
    // "aws_user_pools_id": "us-east-2_Uy7g4w56w",
    // "aws_user_pools_web_client_id":"l5k6g7n905j9llnikmte8mt3e",
    // "oauth": {}
    
};



export default awsmobile;