import firebase from 'firebase';

var firebaseConfig = {
apiKey: "AIzaSyAsILvpuX5Ixmq8VVYlQNX5WUahMdCXgDM",
authDomain: "caryn-notifications.firebaseapp.com",
projectId: "caryn-notifications",
storageBucket: "caryn-notifications.appspot.com",
messagingSenderId: "377816689161",
appId: "1:377816689161:web:8ddab1e742b1aaeacaa6d8",
measurementId: "G-FPENL1BNXG"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);


export default firebase;

