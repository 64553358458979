import React, { Component } from 'react';
import Header from '../Header/Header';
import { Fab } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AskBOmba from '../../Assets/carynIcon.jpg';
import PaymentDataTable from './PaymentDataTable';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import SimpleAccordion from './MyPaymentWalletAccordion';
import PaymentCard from './PaymentCardFront';
import {Modal} from 'react-bootstrap'
export default class MemberNeeds extends Component{
    constructor(props) {
      super(props)
      this.state = {
        ModalFrontIDcard:false,
        ModalBackIDcard:false,
        dataTable:[],
        visible:false, 
        paymentCardData:{},
        memberID:'',
        memberName:'',
        message:"Search for Member Payment Wallet",
      }
      this.myRef = React.createRef()
    }
    componentDidMount=()=> {
      this.myRef.current.scrollTo(0, 0);
      this.setState({dataTable: [{
          amountAuthorized: "1.00",
          cardActivationDate: 1619740800000,
          cardIDNumber: "4",
          cardRequestDate: 1619740800000,
          cardStatus: "Active",
          cvv: "1235",
          expirationDate: "12/29",
          groupNumber: "UHS2",
          id: 227,
          memberFirstName: "test",
          memberLastName: "asp",
          memberNumber: "CTT802030",
          panNumber: "4242424242424242",
          procedureInformation: "test",
          providerAddress1: "980-12 Test Str.",
          providerAddress2: null,
          providerCity: "City",
          providerName: "UNIVERSAL HEALTH FELLOWSHIP",
          providerPostalCode: "23443",
          providerState: "TX",
      }]})   
    }

    openPaymentCard=(rowData)=>{
      this.setState({visible:true,paymentCardData : rowData})
    }
render(){
        return (<div ref={this.myRef}>
            <Header header="Payment Wallet" username="John Doe"/>
            <div className="progInfoContainer container-fluid" style={{zIndex:'0',}}>
              <sapn className="Back_page" onClick={()=>this.goBack()}><ArrowBackIcon style={{ width: '24px', height: '24px', color: ' #543379',marginRight:'5px'}} onClick={()=>this.goBack()} />BACK</sapn>
              <div className="commonWrap" style={{marginTop: '-27px', margin: "0 0px"}}>
              <div className="progInfoSection ">
                <div className="row">
                  <div className="col-md-6">
                    <h1 className="progInfoTitle">My Payment Wallet</h1>
                    </div>
                  <div className="col-md-6" style={{textAlign: 'right'}}>
                      <form onSubmit={(e)=>this.SearchMember(e)}>
                                  <TextField
                                    label="Member ID*"
                                    id="Member ID*"
                                    value={this.state.memberID}
                                    onChange={(e)=> this.setState({memberID:e.target.value})}
                                  />
                                  <TextField
                                    label="Member Name*"
                                    id="Member Name*"
                                    className="inputStyle"
                                    value={this.state.memberName}
                                    onChange={(e)=> this.setState({memberName:e.target.value})}
                                  />
                                  <Fab type="submit" style={{backgroundColor:"#fec465", width:43, height:43, marginLeft:30}} aria-label="add" onClick={()=>this.SearchMember(false)}>
                                    <SearchIcon style={{color:"#fff"}} />
                                  </Fab>
                      </form>
                </div>
                </div>
              </div>
              
  <div className="tablebackgroundouter tablebackgroundouterPaymentWallet">
     <div className=".Rectangle-271needs row">
        <div className="desktopTable">
          {this.state.ShowMember ? 
          <PaymentDataTable data={this.state.dataTable} openPaymentCard={(row)=>this.openPaymentCard(row)} />:
           <div className="Rectangle-271 row" style={{height:250}}>
           <h3 style={{marginTop:50, textAlign: 'center'}}>{this.state.message}</h3>
         </div> }
        </div>
     </div>
  </div>
  <div className="mobileTable">
  {this.state.ShowMember ? 
    <SimpleAccordion data={this.state.dataTable} openPaymentCard={(row)=>this.openPaymentCard(row)}/>:
    <div className="Rectangle-271" style={{height:250, width:'100vw'}}>
           <h3 style={{marginTop:50, textAlign: 'center', padding:"0 15px"}}>{this.state.message}</h3>
    </div> }
  </div>
    <div className="progInfoFooter desktopTable">
      <div className="row">
        <div className="col-md-6" >
        
        </div>
        <div className="col-md-6 dpFlex">
          {/* <div className="elevation"></div> */}
          <div className="footerText" style={{display:'flex', flexDirection:'row'}}>
            <div>
              <p>Need help?</p>
              <p>Chat with our Health Share Representative</p>
              <p>or call 1 (888) 366 6243.</p>
            </div>
            <div style={{marginLeft:12}}>
            <Fab color="#533278" style={{backgroundColor:"#533278"}} aria-label="add" onClick={()=>window.open("https://inf-labs.com/")}>
                <img width="50" src={AskBOmba}/>
              </Fab>
            </div>
          </div>
          
        </div>
      
      </div>
    </div>
    </div>

            
            </div>
            <Modal className="paymentCardModal" show={this.state.visible}  onHide={()=>this.setState({visible : false})} centered  >
                
                <Modal.Body style={{padding:'0px'}}>
                 <PaymentCard paymentData={this.state.paymentCardData} />
                 </Modal.Body>
               
               </Modal>
    </div>) 
    

}
SearchMember=(e)=>{
  if(e)
  e.preventDefault();
  if(this.state.memberID === "1234" && this.state.memberName.toUpperCase()==="CANE WILLIMSON"){
    this.setState({ShowMember:true});
  }else{
    this.setState({message:"Member Not Found", ShowMember:false});
  }
}
goBack=()=>{
  this.props.history.push('/')
}
}