import React from "react";
import Button from '@material-ui/core/Button';
import { Auth } from "aws-amplify";
import Loader from "../Styles/Loader";
import AlertBox from "../Styles/AlertBox";
import PasswordValidations from "./PwdValidations";
import axios from "axios";

export default class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: false,
      alert: false,
      resetPassword: false,
      secondPassword: '',
      firstPassword: '',
      isRevealSecond: false,
      isReveal: false,
      open:false,
      confirm:false,
      loader:false,
    }
  }

  render() {
    return (<div style={{ marginBottom: 50 }}>
      {this.state.loader && <Loader/>}
      {this.screen()}
      <AlertBox email={this.state.email} confirm={this.state.confirm} open={this.state.open} gotoResetPassword={()=>{this.forgotPassword(); this.setState({ open:false})}} handleClose={()=>this.setState({open:false})}/>
    </div>)
  }
  screen = () => {
    if (this.state.resetPassword)
      return <div>
        <div class="reset_note passowrd_validation_center">We have sent a password reset code by email to {this.state.starEmail}. Enter it below to reset your password.</div>
        {this.state.error && <p className="login_new_error_text">{this.state.error}</p>}
        <div className="forgorPwdForm">
          <div>
            <input
              className="login_input_username code"
              id="code"
              type="text"
              autoComplete="new-password"
              value={this.state.code}
              onChange={(e) => this.setState({ code: e.target.value })}
              placeholder="Code"
              name="code"
              required
              onKeyDown={this.handleKeyUp}
            />
          </div>
          <div className="pwdDiv">
            <input
              className="login_input_username"
              id="passw"
              type="password"
              autoComplete="new-password"
              value={this.state.firstPassword}
              onChange={(e) => this.setState({ firstPassword: e.target.value })}
              placeholder="NEW PASSWORD"
              name="password"
              //   ref={this.passwordInput}
              required
              onKeyDown={this.handleKeyUp}
            />
            <span className="p-viewer-forgot-firsttime pwdDivEyeIcon" onClick={() => this.togglePassword('passw')}>
              {!this.state.isReveal ?
                <i className="fa fa-eye-slash" aria-hidden="true" ></i> :
                <i className="fa fa-eye" aria-hidden="true" ></i>
              }
            </span>
          </div>
          <div className="pwdDiv">
            <input
              className="login_input_username"
              id="passwordSecond"
              type="password"
              autoComplete="new-password"
              value={this.state.secondPassword}
              onChange={(e) => this.setState({ secondPassword: e.target.value })}
              placeholder="ENTER NEW PASSWORD"
              name="password"
              //   ref={this.passwordInput}
              required
              onKeyDown={this.handleKeyUp}
            />
            <span className="p-viewer-forgot-secondtime pwdDivEyeIcon" onClick={() => this.togglePassword('passwordSecond')}>
              {!this.state.isRevealSecond ?
                <i className="fa fa-eye-slash" aria-hidden="true" ></i> :
                <i className="fa fa-eye" aria-hidden="true" ></i>
              }
            </span>
          </div>
        </div>


        <div style={{ marginTop: "50px" }} className="passowrd_validation_center">
          <Button
            type="submit"
            class="login_button reset"
            onClick={() => this.changePassword()}
          > CHANGE PASSWORD</Button></div>
        <div className="passowrd_validation_center">
          <PasswordValidations password={this.state.firstPassword} allPassed={(pass) => this.toggleResetBtn(pass)} />
        </div>
        <div class="checkregister_goto_subtext">
          Go back to
          <span class="rege_goto" onClick={() => this.props.gotoLoginScreen()}>
            {' '}
            Sign In
          </span>
        </div>
      </div>
    else
      return (<div className="login_input_container">
        {this.state.error && <p className="login_new_error_text">{this.state.error}</p>}

        <div className="commonDpFlex commonFlexWrap">

          {this.props.PleaseCheck?<div class="checkregister_blue_text_container">
            <div class="checkregister_having_text">Having trouble logging in?</div>
            <div class="checkregister_having_subtext">Let’s check if your email has</div>
            <div class="checkregister_having_subtext">message to reset your password.</div>
          </div>:
          <div class="checkregister_blue_text_container">
            <div class="checkregister_having_text">Forgot your password?</div>
            <div class="checkregister_having_subtext">Enter your Email and we will send a</div>
            <div class="checkregister_having_subtext">message to reset your password.</div>
          </div>
          }

          <input
            className="login_input_username forgot-input"
            type="email"
            name="email"
            pattern="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$"
            required
            placeholder="ENTER YOUR EMAIL"
            value={this.state.email}
            onChange={(e) => this.setState({ email: e.target.value })}
          />
          {this.props.PleaseCheck?<Button
            type="submit"
            class="login_button reset"
            onClick={() => this.checkRegistration()}
          >CHECK REGISTRATION</Button>:
          <Button
            type="submit"
            class="login_button reset"
            onClick={() => this.forgotPassword()}
          >RESET MY PASSWORD</Button>}
        </div>

        <div class="checkregister_goto_subtext">
          Go back to
          <span class="rege_goto" onClick={() => this.props.gotoLoginScreen()}>
            {' '}
            Sign In
          </span>
        </div>
        {/* <AlertBox content={"Hello"} open={this.state.alert} handleClose={()=>this.setState({alert:false})} /> */}
      </div>
      );
  }
  checkRegistration=(e)=>{
    e && e.preventDefault();
    this.setState({loader:true})
    let request = {};
    let user={};
    if (process.env.REACT_APP_BUILD == 'prod') {
     request = {
        "username": "admin",
        "password": "x1TXVUtXL6PaBWam"
      }
      user={
            "cognitoUserPool": "us-east-2_Uy7g4w56w",
            "username": this.state.email
      }
    }else{
      request = {
          "username": "admin",
          "password": "testpass"
      }
      user={
        "cognitoUserPool": "us-east-2_KAeudBRHb",
        "username": this.state.email
      }
    }
    axios.post(process.env.REACT_APP_BASE_URL+"v1/login", request).then(data=>{
      let bearer = data['headers'].authorization
      var array = bearer.split("Bearer ")
      axios.post(process.env.REACT_APP_BASE_URL+"v1/memberportal/getuser", user, { headers: { Authorization: 'Bearer ' + array[1] } }).then(res => {
         if(res.data.userStatus==="CONFIRMED"){
            this.setState({open:true, confirm:true, loader:false})
         }
      }).catch(err => {
        this.setState({open:true, confirm:false, loader:false})
      })
    })
  }
  forgotPassword = e => {
    e && e.preventDefault();
    this.setState({loader:true})
    Auth.forgotPassword(this.state.email.trim())
    .then(data => {
        console.log(data)
        this.setState({alert:true, loader:false, starEmail:data.CodeDeliveryDetails.Destination, resetPassword:true, error:""})
     }) 
    .catch(err => {
      console.log(err)
        this.setState({resetPassword:false,loader:false, error:err.message==="Username/client id combination not found."?"User not Found":err.message})
    });
 }
  changePassword = () => {

    if (this.state.firstPassword == this.state.secondPassword) {
      this.setState({ loader: true })
      Auth.forgotPasswordSubmit(this.state.email, this.state.code, this.state.firstPassword).then(user => {
        window.location.reload();
      }).catch(err => {
        this.setState({ error: err.message, loader: false })
      })
    } else {
      this.setState({ error: 'Please enter same password' })
    }
  }
  toggleResetBtn = (allPassed) => {
    this.setState({ disableSendBtn: !allPassed })
  }
  togglePassword = (id) => {
    if (id === "passwordSecond") {
      this.setState({ isRevealSecond: !this.state.isRevealSecond })
      var pass = document.getElementById(id)
      if (pass.type == 'password') {
        pass.type = "text"
      } else {
        pass.type = "password"
      }
    }
    else {
      this.setState({ isReveal: !this.state.isReveal })
      var pass = document.getElementById(id)
      if (pass.type == 'password') {
        pass.type = "text"
      } else {
        pass.type = "password"
      }
    }
  }
}