import { Component } from 'react';
import UHSLogo from '../Image/UHS_Logo_RGB_600px_200px.png';
import PHSLogo from '../Image/phcs_id_practitioner_ancillary_only.jpg';
import {Grid} from '@material-ui/core';
import moment from 'moment'
import './IDcardModal.css';

export default class ModalIDcardFront extends Component{
    constructor(props) {
      super(props)
      this.state = {
        membersince:'',
      }
    }
    componentDidMount(){
      this.dateformat();
    }
    dateformat() {
      let enrollmentDate = this.getDateInUTC(this.props.data.memberIdCardList[0].enrollmentDate, true) 
      var day = moment(enrollmentDate).format('DD')
      var mon = moment(enrollmentDate).format('MM')
      var year = moment(enrollmentDate).format('YYYY')
      var date = mon + "/" + day + "/" + year
      this.setState({ membersince: date })
    }
render(){
        return (
        <div className="fviewModal">
             <Grid container>
             <div className="fv_background_imageModal">
             <Grid item md={12}>
               <div className="fv_top_image_containerModal">
                    <Grid item md={6}>
                      <img src={UHSLogo} class="fv_left_logoModal img-fluidModal" />
                    </Grid>
                    <Grid item md={6}>
                    {this.props.network =='PHCS' || this.props.network =='smartshare25' || this.props.network =='smartshare50' || this.props.network =='healthyLife'?
                      <img src={PHSLogo} class="fv_right_logoModal  img-fluidModal"/>:null}
                    </Grid>
               </div>
               </Grid>
               <Grid item md={12}>
               <div class="fv_card">
                <Grid item md={6}>
                  <div class="fv_left_containerModal">
                  {this.props.data.memberIdCardList && this.props.data.memberIdCardList.map((data)=>
                    <div class="fv_list_view">
                    <Grid item md={3}><div class="fv_list_adultnameModal">{data.firstName} {data.lastName}</div></Grid>
                    <Grid item md={3}> <div class="fv_list_usernameModal">{this.props.data && this.props.data.memberIdCardList[0].planInfo.map(res=>(res.idcardField === "prefix"?res.fieldValue:null))}{data.empId}</div></Grid>
                    </div>)}


                      {/* <div class="fv_list_view">
                      <Grid item md={3}><div class="fv_list_adultnameModal">CANE WILLIMSON</div></Grid> 
                      <Grid item md={3}><div class="fv_list_usernameModal">1234</div></Grid> 
                      </div> */}
                  </div>
                  </Grid>
                <Grid item md={6}>
                <div class="fv_right_containerModal">
                  <div class="fv_joined_textModal">Program Details</div>
                  <div class="fv_right_table1">
                  <Grid item md={3}>
                    <div class="fv_left_tableModal">
                      <div class="fv_table_left_textModal">Member since</div>
                      {this.props.data.memberIdCardList[0].groupNo && <div class="fv_table_left_textModal">Group No.</div>}
                      <div class="fv_table_left_textModal">Program ID</div>
                    </div>
                    </Grid>
                    <Grid item md={3}>
                    <div class="fv_right_table">
                      <div class="fv_table_right_textModal">{this.state.membersince}</div>
                      <div class="fv_table_right_textModal">{this.props.data.memberIdCardList[0].groupNo}</div>
                      <div class="fv_table_right_textModal">{this.props.data.memberIdCardList[0].planId}</div>
                    </div>
                    </Grid>
                  </div>
                </div>
                </Grid>
                <div class="fv_space_2table"></div>
               </div>
               </Grid>
               <Grid item md={12}>
               {(() => {
                switch ( this.props.network) {
                  case 'PHCS':
                    return (
                      <div class="fv_center_details_textModal">
                      Universal HealthShare plans from Universal Health Fellowship are health care cost sharing ministry programs, not insurance. We are IN NETWORK for Multiplan/PHCS Practitioner & Ancillary Network. 
                      <br/>Providers & Members confirmations and questions 
                  </div>
                    )
                    case 'healthyLife':
                      return (
                        <div class="fv_center_details_textModal">
                        Universal HealthShare plans from Universal Health Fellowship are health care cost sharing ministry programs, not insurance. We are IN NETWORK for MultiPlan’s PHCS Network for practitioner & ancillary providers.
                        <br/> Providers & Members confirmations and questions &nbsp; 
                    </div>
                      )

                  case 'smartshare25':
                    return (
                      <div class="fv_center_details_textModal">
                      Universal HealthShare plans from Universal Health Fellowship are health care cost sharing ministry programs, not insurance. We are IN NETWORK for MultiPlan’s PHCS Network for practitioner & ancillary providers.
                      <br/> Providers & Members confirmations and questions
                      
                  </div>
                    )
                    case 'smartshare50':
                      return (
                        <div class="fv_center_details_textModal">
                        Universal HealthShare plans from Universal Health Fellowship are health care cost sharing ministry programs, not insurance. We are IN NETWORK for MultiPlan’s PHCS Network for practitioner & ancillary providers. 
                        <br/>Providers & Members confirmations and questions &nbsp;
                        
                    </div>
                      )

                  case 'Smartshare':
                    return (
                      <div class="fv_center_details_textModal">
                      Universal HealthShare plans from Universal Health Fellowship are health care cost sharing ministry programs, not insurance. We are IN NETWORK for Multiplan/PHCS Practitioner & Ancillary Network. Providers & Members confirmations and questions 
                    
                  </div>
                    )

                  case 'AFMC':
                    return (
                      <div class="fv_center_details_textModal">
                      Universal HealthShare plans from Universal Health Fellowship are health care cost sharing ministry programs,
not insurance. We are IN NETWORK for The Arizona Foundation for physician and ancillary services. Providers & Members confirmations and questions 
                  </div>
                    )
                }
              })()}
                
                </Grid>
             </div>
             </Grid>
        </div>)
    }
    getDateInUTC = (date, getInMillisecs) => {
      if (date) {  
        let newDateTime = date + new Date(date).getTimezoneOffset() * 60 * 1000;  
        if (getInMillisecs) { 
          return newDateTime 
        }
        return new Date(newDateTime)
      }
    return date 
    }
}