import React, { Component } from 'react';
import Header from '../Header/Header';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ComponentCommonBottom from '../ComponentFooter/ComponentCommonBottom';
import ErrorIcon from '@material-ui/icons/Error';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import ReminderNotification from '../Header/Images/notification_reminder_icon.svg';
import AlertNotification from '../Header/Images/notification_alert_icon.svg'
import UpdateNotification from '../Header/Images/notification_update_icon.svg'
import SurveyNotification from '../Header/Images/notification_survey_icon.svg'
import PromoNotification from '../Header/Images/notification_promo_icon.svg'
import AnnouncementNotification from '../Header/Images/notification_announcement_icon.svg'
import Button from '@material-ui/core/Button';
import { Modal } from 'react-bootstrap';
import { Grid, IconButton} from "@material-ui/core";
import Footer from '../Footer/Footer'

export default class MyNotificationScreen extends Component{
    constructor(props) {
      super(props)
      this.state = {
        dataUnread: [],
        dataRead: [],
        data: [],
        message: null,
        loader: false,
        date: '',
        page: 0,
        rowsPerPage: 20,
        rowCount: 0,
        createdDate: [],
        tempNotAvailableModal:false,
        mail:JSON.parse(localStorage.getItem('authUser')).attributes.email,
      }
    }
    componentDidMount() {
        this.getNotification();
        document.addEventListener("myevent1", (event) => {
          this.setState({ page: 0 }, () => this.getNotification());
        });
      }
      getNotification = () => {
        this.setState({
          loader: true
        })
        axios.get(process.env.REACT_APP_BASE_URL+"v8/Twillio/"+'getNotificationDetails/' + this.state.mail + '?page=' + this.state.page + '&size=20')
          .then(res => {
            console.log(res)
            if (res.data && res.data.response) {
              this.setState({
                data: res.data.response.notificationDetails,
                rowCount: res.data.response.pageList,
                loader: false
              })
            } else {
              this.setState({ message: "Notification not available !", loader: false })
            }
          })
      }
      handleChangePage = (event, newPage) => {
        console.log("new page---", newPage)
        this.setState({ page: newPage }, () => this.getNotification())
      };
    render() {
        return(
            <div>
                <Header header="My Notification"/>
                <div className="progInfoContainer container-fluid" style={{ zIndex: '0' }}>
                    <div className="Back_page" onClick={() => this.props.history.push("/")}><ArrowBackIcon style={{ width: '24px', height: '24px', color: ' #543379', marginRight: '5px' }} />BACK</div>
                    <div className="commonWrap">
                        <div className="progInfoSection">
                                <div className="col-md-12 row">
                                    <div className="col-md-6"><span className="progInfoTitle">My Notification</span></div>
                                    <div className="col-md-2"></div>
                                </div>
                        </div>
                        <div className="line"></div>
                        <div className="tablebackgroundouter tablebackgroundouterPaymentWallet">
                        {this.state.data && this.state.data.length > 0 ? 
                        <div>
                        <TableContainer component={Paper} style={{maxHeight:'300px',overflow:'auto'}}>
                            <Table stickyHeader aria-label="sticky table">
                            {
                                this.state.data.map((data, index) => {
                                    return (data.type == 'REMINDER' || data.type == 'reminder') ?
                                    <TableRow key={index}>
            
            
            
                                      <TableCell class={data.status == 'delivered' ? "notification_details_mainDiv" : 'notification_details_mainDiv_read'}>
                                       
                                          <>
                                            {/* <div key={index} class={data.status=='delivered'?"notification_details_mainDiv":'notification_details_mainDiv_read'} > */}
                                            <img src={ReminderNotification} class="Notification_category_img_details" />
                                            <div class="notification_category_Message_details" onClick={() =>this.onClickNotification(data.notificationID, data.urlPath)}>
                                              <p class="notification_category_label_details">{data.title}
                                                <span className="notification_details_Date">{this.dateformat(data.createdDate)}</span>
            
                                              </p>
                                              <div className="notification_details_message">{data.message}</div>
                                            </div>
                                            {/* </div> */}
                                          </>
                                          </TableCell>
            
            
                                        </TableRow>
                                          :
                                          (data.type == 'ALERT' || data.type == 'alert') ?
                                            <>
                                            <TableRow key={index}>
            
            
            
                                <TableCell class={data.status == 'delivered' ? "notification_details_mainDiv" : 'notification_details_mainDiv_read'}>
                                              {/* <div key={idx} class={data.status=='delivered'?"notification_details_mainDiv":'notification_details_mainDiv_read'}> */}
                                              <img src={AlertNotification} class="Notification_category_img_details" />
                                              <div class="notification_category_Message_details" onClick={() =>this.onClickNotification(data.notificationID, data.urlPath)}>
                                                <p class="notification_category_label_details">{data.title}
                                                  <span className="notification_details_Date">{this.dateformat(data.createdDate)}</span>
            
                                                </p>
                                                <div className="notification_details_message">{data.message}</div>
                                                {/* <span className="notification_details_Date">{moment(data.created_date).format('MMMM DD, YYYY')}</span>
                                        <span className="notification_details_Time">{moment(data.created_date).format("HH:mm A")}</span> */}
                                              </div>
                                              {/* </div> */}
                                              </TableCell>
            
            
            </TableRow>
                                            </>
                                            :
                                            (data.type == 'UPDATE' || data.type == 'update') ?
                                              <>
                                               <TableRow key={index}>
            
            
            
                                          <TableCell class={data.status == 'delivered' ? "notification_details_mainDiv" : 'notification_details_mainDiv_read'}>
                                                <img src={UpdateNotification} class="Notification_category_img_details" />
                                                <div class="notification_category_Message_details" onClick={() =>this.onClickNotification(data.notificationID, data.urlPath)}>
                                                  <p class="notification_category_label_details">{data.title}
                                                    {/* (moment.duration(moment(new Date()).diff(data.created_date))).asHours()/24 ==1?moment(data.created_date).format('LT'):moment(data.created_date).minutes() */}
                                                    <span className="notification_details_Date">{this.dateformat(data.createdDate)}</span>
            
                                                  </p>
                                                  <div className="notification_details_message">{data.message}</div>
                                                  {/* <span className="notification_details_Date">{moment(data.created_date).format('MMMM DD, YYYY')}</span>
                                        <span className="notification_details_Time">{moment(data.created_date).format("HH:mm A")}</span> */}
                                                </div>
                                                </TableCell>
            
            
                                        </TableRow>
                                              </>
                                              :
                                              (data.type == 'SURVEY' || data.type == 'survey') ?
                                                <>
                                                <TableRow key={index}>
            
            
            
            <TableCell class={data.status == 'delivered' ? "notification_details_mainDiv" : 'notification_details_mainDiv_read'}>
                                                  <img src={SurveyNotification} class="Notification_category_img_details" />
                                                  <div class="notification_category_Message_details" onClick={() =>this.onClickNotification(data.notificationID, data.urlPath)}>
                                                    <p class="notification_category_label_details">{data.title}
                                                      <span className="notification_details_Date">{this.dateformat(data.createdDate)}</span>
            
                                                    </p>
                                                    <div className="notification_details_message">{data.message}</div>
                                                    {/* <span className="notification_details_Date">{moment(data.created_date).format('MMMM DD, YYYY')}</span>
                                    <span className="notification_details_Time">{moment(data.created_date).format("HH:mm A")}</span> */}
                                                  </div>
                                                  </TableCell>
            
            
                                        </TableRow>
                                                </>
                                                :
                                                (data.type == 'PROMOTIONAL' || data.type == 'promotional') ?
                                                  <>
                                                    <TableRow key={index}>
            
            
            
            <TableCell class={data.status == 'delivered' ? "notification_details_mainDiv" : 'notification_details_mainDiv_read'}>
                                                    <img src={PromoNotification} class="Notification_category_img_details" />
                                                    <div class="notification_category_Message_details" onClick={() =>this.onClickNotification(data.notificationID, data.urlPath)}>
                                                      <p class="notification_category_label_details">{data.title}
                                                        <span className="notification_details_Date">{this.dateformat(data.createdDate)}</span>
            
                                                      </p>
                                                      <div className="notification_details_message">{data.message}</div>
                                                      {/* <span className="notification_details_Date">{moment(data.created_date).format('MMMM DD, YYYY')}</span>
                                            <span className="notification_details_Time">{moment(data.created_date).format("HH:mm A")}</span> */}
                                                    </div>
                                                    </TableCell>
            
            
                                        </TableRow>
                                                  </>
                                                  :
                                        //           (data.type == 'ANNOUNCEMENT' || data.type == 'announcement') ?
                                        //           <>
                                        //             <TableRow key={index}>
            
            
            
                                        //           <TableCell class={data.status == 'delivered' ? "notification_details_mainDiv" : 'notification_details_mainDiv_read'}>
                                        //             <img src={AnnouncementNotification} class="Notification_category_img_details" />
                                        //             <div class="notification_category_Message_details" onClick={() =>this.onClickNotification(data.notificationID, data.urlPath)}>
                                        //               <p class="notification_category_label_details">{data.title}
                                        //                 <span className="notification_details_Date">{this.dateformat(data.createdDate)}</span>
            
                                        //               </p>
                                        //               <div className="notification_details_message">{data.message}</div>
                                                    
                                        //             </div>
                                        //             </TableCell>
            
            
                                        // </TableRow>
                                        //           </>
                                                 
                                        //             :
                                                    null
                                })
                            }
                            </Table>
                        </TableContainer>
                        <TablePagination
                                rowsPerPageOptions={[]}
                                component="div"
                                count={this.state.rowCount}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                />
                        </div>:
                        <div className="row rowMargin">
                            <div className="msgWrapper">
                            <div className="row">
                                <div className="col-2 col-md-1 text-center">
                                <ErrorIcon style={{ color: "#fff", fontSize:'40px' }} />
                                </div>
                                <div className="col-10 col-md-11 text-left msgVertCenter">
                                    <h6>You currently have no new notifications.</h6>
                                </div>
                            </div>
                            </div>                
                        </div>}
                        </div>
                        <ComponentCommonBottom/>
                    </div>
                </div>
                <Footer></Footer>
                <Modal  show={this.state.noticeModal}  centered className="ChangPayModal paymentWalletModal" backdrop='static'>
                        <Modal.Body style={{maxHeight:'410px',overflowY:'auto'}}>
                        <Grid
                                                container
                                                direction="row"
                                                justify="space-between"
                                                alignItems="flex-start"
                                                style={{flexWrap:'nowrap'}}
                                            >
                                                <span class="Change-Payment-Method" style={{lineHeight:'1.3'}}>Message</span>
                                                <IconButton aria-label="close"  onClick={() => this.setState({ noticeModal: false })} style={{marginTop:'-13px'}}>
                                                <CloseIcon />
                                                </IconButton>
                                            </Grid>
                            <div>
                            <Grid container>
                            </Grid>
                                <pre class="The-fellowship-incurs-additional-fees-related-to-p" style={{margin:'0px',padding:'15px',overflowX: 'auto',
                                whiteSpace: "pre-wrap"}}>
                            We’re facing some technical difficulties, due to
                            which this feature is currently unavailable. For
                            support, call Member Services at (855) 030-4941, Monday through Friday, 8.00am to
                            8.00pm CST.

                                </pre>
                        </div>
                            </Modal.Body>
                        <Modal.Footer style={{justifyContent: 'center'}}>
                            <Button variant="contained" onClick={()=>this.setState({noticeModal:false})} style={{marginRight:'15px', backgroundColor:'red'}} className="disablecardPopup">
                                               Close
                                                </Button>
                        </Modal.Footer>
                        </Modal>
         
            </div>
        )
    }
    onClickNotification=(id, url, type)=>{
        let obj={"notificationId":id}
        axios.post(process.env.REACT_APP_BASE_URL+"v8/Twillio/"+"updateNotificationStatus", obj).then(response=>{
          if(url=='ProgramInformation'){
            window.location.href='/MemberInformation' 
          }
          if(url=='Needs'||url=='MyNeeds'){
            window.location.href='/MemberNeeds'
          }
          if(url=='MobileTransaction'){
         // window.location.href='/PaymentWallet'
         this.setState({noticeModal:true})
          }
        })
      }
    getDateInUTC = (date, getInMillisecs) => {

        if (date) {
    
          let newDateTime = new Date(date)
    
          return new Date(newDateTime)
    
        }
    
        return date
    
      }
    
    
    
      dateformat(date) {
        const enrollmentDate = this.getDateInUTC(date, true)
        let nowdate = this.getDateInUTC(new Date(), true)
    
        var day = moment(enrollmentDate).format('DD')
    
        var mon = moment(enrollmentDate).format('MM')
    
        var year = moment(enrollmentDate).format('YYYY')
    
        var date = mon + "/" + day + "/" + year
    
        var todayDate = moment(nowdate).format('MM/DD/YYYY')
        let hr = moment(enrollmentDate).format('hh')
    
        if (date !== todayDate) {
    
          return moment(date).format('MMM DD, YYYY')
    
        } else {
    
    
          if (hr == '00') {
            return moment(enrollmentDate).format('m') + 'mins'
          }
          if (hr > 12) {
            return moment(enrollmentDate).format('hh:mm A')
          } else {
            return moment(enrollmentDate).format('hh:mm A')
          }
    
        }
    
      }
}