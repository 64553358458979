import { Component } from 'react';
import Sidebar from 'react-sidebar';
import MenuIcon from '@material-ui/icons/Menu'
import { withRouter } from 'react-router';
import AppsIcon from '@material-ui/icons/Apps'
import { Row, Container, Col, Button, Card, Modal } from 'react-bootstrap'
import { withStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { Link } from 'react-router-dom'
import LeftDrawer from './LeftDrawer'
import RightDrawer from './RightDrawer'
import customStyle from './stylesheet_UHS';
import LogoIcon from './Images/logo (1).svg';
import CloseIcon from '@material-ui/icons/Close';
import HealthIcon from './Images/my health icon.png';
import Modal1 from 'react-awesome-modal';
import Modal2 from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ReminderNotification from './Images/notification_reminder_icon.svg';
import AlertNotification from './Images/notification_alert_icon.svg'
import UpdateNotification from './Images/notification_update_icon.svg'
import SurveyNotification from './Images/notification_survey_icon.svg'
import PromoNotification from './Images/notification_promo_icon.svg'
import AnnouncementNotification from './Images/notification_announcement_icon.svg'
import './Drawer.css';
import AIcarynIcon from './Images/askcaryn.png';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MemberAppIcon from '../../Assets/my health icon.png';
import MemberServiceIcon from '../../Assets/my health.svg';
import moment from "moment";
import Documents from '../Documents/Documents';
import MyNotification from '../MyNotification/MyNotification';
import Notices from "../Notices/Notices";
import firebase from '../../firebase';
import axios from 'axios';
import findaProviderIcon from '../../Assets/find-a-provider-icon-active.svg';

const NextButton = withStyles(
  customStyle.viewBtn
)(Button);
class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarOpen: false,
      dockval: false,
      rightslider: false,
      rightdockval: false,
      showHealthCard: false,
      bigcard: '',
      smallcard: '',
      rightcard: '',
      x: false,
      yellowPopup: true,
      username: "",
      digitalcard: false,
      documentsmodal: false,
      contactmodal: false,
      mqmoduleAlert: null,
      mqloader: false,
      myneedsfooter: false,
      contactCardData: null,
      documentCardData: null,
      tempNotAvailableModal: false,
      notificationData: [],
      unReadData: [],
      notificationCount: 0,
      anchorEl: null,
      open: false,
      show: false,
      width: 0, height: 0,
      mail: JSON.parse(localStorage.getItem('authUser')).attributes.email,
      ModalpopUp: false,
      findproviderUrl:"",
    }

  }
  componentDidMount() {
    var authUser = localStorage.getItem('authUser');
    var user = JSON.parse(authUser)
    this.setState({ username: user.attributes.name })
    this.getNotification()
    const msg = firebase.messaging.isSupported() ? firebase.messaging() : null;
    if (msg) {
      msg.onMessage(payload => {
        this.getNotification()
        const myEvent = new CustomEvent("myevent", {
          detail: {},
          bubbles: true,
          cancelable: true,
          composed: false,
        })
        document.dispatchEvent(myEvent);
        console.log("onMessage:+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++home ", payload);
      });
    }
  }

  getNotification = () => {
    axios.get(process.env.REACT_APP_BASE_URL + "v8/Twillio/" + 'getNotificationDetails/' + this.state.mail)
      .then(res => {
        if (res.data && res.data.response) {
          this.setState({
            notificationData: res.data.response.notificationDetails,
            notificationCount: res.data.response.count
          }, () => {
            if (this.state.notificationData) {
              let dt = this.state.notificationData.filter((val, index) => (val.status == 'sent'))
              let newArr = dt.slice(0, 5)
              this.setState({ unReadData: dt })
            }
          })
        } else {
          this.setState({ notificationData: [] })
        }

      })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  onSetSidebarOpen = () => {
    if (this.state.sidebarOpen === undefined || this.state.sidebarOpen === false)
      this.setState({ sidebarOpen: true, rightslider: false })
    else
      this.setState({ sidebarOpen: false, rightslider: false })
  }
  onrightsliderClick = () => {
    var rightslider = this.state.rightslider;
    this.setState({ rightslider: !rightslider, rightdockval: !this.state.rightdockval, sidebarOpen: false, dockval: false })
  }
  closeDocumentmodal() {
    this.setState({ documentsmodal: false })
  }
  leftdrawerclose(val) {
    // alert("webhome drawer call")
    this.setState({ sidebarOpen: false, })
    this.onCardClick();
  }
  onCardClick = () => {
    this.setState({ loader: true })
    this.gethealthcarddata()
    if ((localStorage.getItem("CLIENT_ID") === '6548' || localStorage.getItem("CLIENT_ID") === 6548) || localStorage.getItem("CLIENT_ID") === '4367' || localStorage.getItem("CLIENT_ID") === 4367) {
      this.setState({
        showHealthCard: true,
        // showHealthToolCard:true,
        visibleHealthy: true,
        loader: false,
        visible: false,
      })
    } else {
      this.setState({
        showHealthCard: true,
        visible: true,
        visibleHealthy: false,
      });
    }
  }

  setContactandCardID(data) {
    data.memberIdCardList[0].planInfo.map((data, index) => {
      if (data.idcardField === "contact number") {
        this.setState({ contactNumber: data.fieldValue })
      }
      if (data.idcardField === "card id") {
        this.setState({ cardId: data.fieldValue })
      }
    })

  }
  drawercloseExpenseOpen() {
    this.setState({ sidebarOpen: false, digitalcard: true, })
    this.openProgramInfo();
  }
  openProgramInfo() {
    this.setState({
      loader: true
    })
    this.setState({
      programInfoModal: true
    })
  }

  documentscardopen = (flag, enable) => {
    this.setState({ sidebarOpen: false, documentsmodal: true })
  }
  contactscardopen() {
    this.setState({ sidebarOpen: false, contactmodal: true })
  }
  openTempModal = () => {
    this.setState({
      sidebarOpen: false,
      tempNotAvailableModal: true
    })
  }
  yellowpopupClose() {
    localStorage.setItem('popupShow', "false")
    this.setState({ yellowPopup: false })
  }
  openFindProviderModal = () => {
    // this.setState({ findproviderUrl: "http://findprovider.universalhealthfellowship.org/" }, () => {
    //   this.setState({ sidebarOpen: false, ModalpopUp: true })
    // })
    window.open("http://findprovider.universalhealthfellowship.org/");
  }
  render() {
    return (
      <div style={{ display: 'block' }}>
        <Sidebar
          sidebar={
            <LeftDrawer username={this.state.username}
              drawercloseparent={this.leftdrawerclose}
              drawercloseExpenseOpen={this.drawercloseExpenseOpen}
              documentsopen={() => this.documentscardopen()}
              contactopen={this.contactscardopen}
              notificationModal={() => this.setState({ notificationModal: true, sidebarOpen: false })}
              noticesModal={() => this.setState({ noticesModal: true, sidebarOpen: false })}
              openTempModal={this.openTempModal}
              pixel={this.state.width}
              header={this.props.header}
              findProviderOpen={() => this.openFindProviderModal()}
            />
          }
          open={this.state.sidebarOpen}
          onSetOpen={this.onSetSidebarOpen}
          sidebarClassName="sideBarWrap"
          styles={{ sidebar: { background: 'white', width: '304px', position: 'fixed', zIndex: '4' } }}
        >

          <div class="mobile_full_size">
            <div className="h_new">
              <div className="h_left_icon_div">
                <div className="h_menuicon_draweropen">
                  <MenuIcon className="menuICon" style={{ color: '#ffffff' }} onClick={() => this.onSetSidebarOpen()} />
                  {this.props.header === "Dashboard" ?
                    <MenuIcon className="menuIConhidden" style={{ color: '#ffffff' }} onClick={() => this.onSetSidebarOpen()} />
                    : this.props.header === "YOUR AI-POWERED ASSISTANT" ?
                      <img className="arrowICon" width="72px" src={AIcarynIcon} />
                      : <ArrowBackIcon className="arrowICon" style={{ color: '#ffffff' }} onClick={() => this.props.history.push('/')} />}
                </div>
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <img src={LogoIcon} className="h_white_logo" />
                  {this.props.header === "YOUR AI-POWERED ASSISTANT" ? <div className="header_name" style={{ marginLeft: 13, fontSize: 18, fontFamily: 'Tungsten' }}>{this.props.header}</div> : <div className="header_name">{this.props.header}</div>}
                </Link>


              </div>
              {/* Notification section */}
              <div className="d-flex">
                {this.props.header === "YOUR AI-POWERED ASSISTANT" ?
                  <Link to="/" style={{ textDecoration: 'none', float: 'right' }}>
                    <div className="header_name"><CloseIcon /></div>
                  </Link>
                  : <div className="appsicon_cursor">
                    <div>

                      <IconButton onClick={this.handleClick}>
                        <Badge badgeContent={this.state.notificationCount} color="error">
                          <NotificationsIcon style={{ color: '#ffffff' }} />
                        </Badge>
                      </IconButton>
                      <IconButton>
                        <Badge color="error">
                          <AccountCircleIcon style={{ color: '#ffffff' }} />
                        </Badge>
                      </IconButton>
                      <Popper transition
                        open={this.state.open}
                        anchorEl={this.state.anchorEl}
                        className='notification_paper_shadow'
                      >
                        {({ TransitionProps }) => (
                          <ClickAwayListener onClickAway={this.handleClickAway}>

                            <Fade {...TransitionProps} timeout={350}>
                              <Paper>
                                {
                                  this.state.unReadData && this.state.unReadData.slice(0, 5).map((data, index) => {

                                    return data.status == 'sent' && (data.type == 'REMINDER' || data.type == 'reminder') ?
                                      <div key={index} className="notification_details_mainDiv"
                                        onClick={() => this.onClickUpdateNotificationStatus(data.notificationID, data.urlPath)}>
                                        <img src={ReminderNotification} className="Notification_category_img_details" />

                                        <div className="notification_category_Message_details">
                                          {/* <Link to="/Notification" style={{ textDecoration: 'none' }}> */}
                                          <p className="notification_category_label_details">{data.title}
                                            <span className="notification_details_Date">{this.dateformat(data.created_date)}</span>

                                          </p>
                                          {/* </Link> */}
                                          <div className="notification_details_message">{data.message}</div>
                                          {/* <span
                                            className="notification_details_Date">{moment(data.created_date).format('MMMM DD, YYYY')}</span>
                                          <span
                                            className="notification_details_Time">{moment(data.created_date).format("HH:mm A")}</span> */}
                                        </div>

                                      </div>
                                      :
                                      data.status == 'sent' && (data.type == 'ALERT' || data.type == 'alert') ?
                                        <div key={index} className="notification_details_mainDiv"
                                          onClick={() => this.onClickUpdateNotificationStatus(data.notificationID, data.urlPath)}>

                                          <img src={AlertNotification} className="Notification_category_img_details" />
                                          {/* <Link to="/Notification" style={{ textDecoration: 'none' }}> */}
                                          <div className="notification_category_Message_details">
                                            <p className="notification_category_label_details">{data.title}
                                              <span className="notification_details_Date">{this.dateformat(data.created_date)}</span>

                                            </p>
                                            <div className="notification_details_message">{data.message}</div>
                                            {/* <span
                                                className="notification_details_Date">{moment(data.created_date).format('MMMM DD, YYYY')}</span>
                                              <span
                                                className="notification_details_Time">{moment(data.created_date).format("HH:mm A")}</span> */}
                                          </div>
                                          {/* </Link> */}
                                        </div>
                                        :
                                        data.status == 'sent' && (data.type == 'UPDATE' || data.type == 'update') ?
                                          <div key={index} className="notification_details_mainDiv"
                                            onClick={() => this.onClickUpdateNotificationStatus(data.notificationID, data.urlPath)}>
                                            <img src={UpdateNotification}
                                              className="Notification_category_img_details" />
                                            {/* <Link to="/Notification" style={{ textDecoration: 'none' }}> */}
                                            <div className="notification_category_Message_details">
                                              <p className="notification_category_label_details">{data.title}
                                                <span className="notification_details_Date">{this.dateformat(data.created_date)}</span>

                                              </p>
                                              <div className="notification_details_message">{data.message}</div>
                                              {/* <span
                                                  className="notification_details_Date">{moment(data.created_date).format('MMMM DD, YYYY')}</span>
                                                <span
                                                  className="notification_details_Time">{moment(data.created_date).format("HH:mm A")}</span> */}
                                            </div>
                                            {/* </Link> */}
                                          </div>
                                          :
                                          data.status == 'sent' && (data.type == 'SURVEY' || data.type == 'survey') ?
                                            <div key={index} className="notification_details_mainDiv"
                                              onClick={() => this.onClickUpdateNotificationStatus(data.notificationID, data.urlPath)}>
                                              <img src={SurveyNotification}
                                                className="Notification_category_img_details" />
                                              {/* <Link to="/Notification" style={{ textDecoration: 'none' }}> */}
                                              <div className="notification_category_Message_details">
                                                <p className="notification_category_label_details">{data.title}
                                                  <span className="notification_details_Date">{this.dateformat(data.created_date)}</span>

                                                </p>
                                                <div className="notification_details_message">{data.message}</div>
                                                {/* <span
                                                    className="notification_details_Date">{moment(data.created_date).format('MMMM DD, YYYY')}</span>
                                                  <span
                                                    className="notification_details_Time">{moment(data.created_date).format("HH:mm A")}</span> */}
                                              </div>
                                            </div>
                                            :
                                            data.status == 'sent' && (data.type == 'PROMOTIONAL' || data.type == 'promotional') ?
                                              <div key={index} className="notification_details_mainDiv"
                                                onClick={() => this.onClickUpdateNotificationStatus(data.notificationID, data.urlPath)}>
                                                <img src={PromoNotification}
                                                  className="Notification_category_img_details" />
                                                <div className="notification_category_Message_details">
                                                  <p className="notification_category_label_details">{data.title}
                                                    <span className="notification_details_Date">{this.dateformat(data.created_date)}</span>

                                                  </p>
                                                  <div className="notification_details_message">{data.message}</div>
                                                </div>
                                              </div>
                                              :
                                              // data.status=='sent' &&  (data.type=='ANNOUNCEMENT'|| data.type=='announcement') ?
                                              //   <div key={index} className="notification_details_mainDiv"
                                              //        onClick={() => this.onClickUpdateNotificationStatus(data.notificationID,data.urlPath)}>
                                              //     <img src={AnnouncementNotification}
                                              //          className="Notification_category_img_details"/>
                                              //     {/* <Link to="/Notification" style={{ textDecoration: 'none' }}> */}
                                              //       <div className="notification_category_Message_details">
                                              //         <p className="notification_category_label_details">{data.title}
                                              //          <span className="notification_details_Date">{this.dateformat(data.created_date)}</span>

                                              //         </p>
                                              //         <div className="notification_details_message">{data.message}</div>
                                              //       </div>
                                              //   </div>
                                              //   :
                                              null
                                  })
                                }
                                {/* <Typography >The content of the Popper</Typography> */}


                                {this.state.unReadData && this.state.unReadData.length >= 5 ?
                                  <div class="notification_details_seeAll" >

                                    <div class="notification_category_Message_details">
                                      <p class="notification_category_label_details">
                                        <Link to="/MyNotification" style={{ textDecoration: 'none', color: '#00000' }} onClick={() => this.setState({ notificationCount: 0 })}> <span className="notification_details_Date" >See all</span></Link>

                                      </p>

                                    </div>

                                  </div>
                                  :
                                  null}
                              </Paper>
                            </Fade>
                          </ClickAwayListener>
                        )}
                      </Popper>
                    </div>
                  </div>}
              </div>
              {/*End Notification*/}



            </div>
            {/* <div class="" style={{marginTop:'80px'}}>
         
                </div> */}
          </div>
          {/* Documents Card Open and Close */}
          {/* <Modal className="ModalDocuments" show={this.state.documentsmodal}  onHide={()=>this.setState({documentsmodal : false})} centered  > */}
          <Modal1 visible={this.state.documentsmodal} className="modalWidth" width="23%" effect="fadeInUp" onHide={() => this.setState({ documentsmodal: false })}>
            <div>
              <Documents modal={true} closeModal={() => this.setState({ documentsmodal: false })} />
            </div>
          </Modal1>
          <Modal1 visible={this.state.notificationModal} className="modalWidth" width="23%" effect="fadeInUp" onHide={() => this.setState({ notificationModal: false })}>
            <div>
              <MyNotification modal={true} closeModal={() => this.setState({ notificationModal: false })} />
            </div>
          </Modal1>
          <Modal1 visible={this.state.noticesModal} className="modalWidth" width="23%" effect="fadeInUp" onHide={() => this.setState({ noticesModal: false })}>
            <div>
              <Notices modal={true} closeModal={() => this.setState({ noticesModal: false })} />
            </div>
          </Modal1>
          {/* Documents End Card Open and Close */}

          {this.state.digitalheathcard && this.state.network ? (
            <Modal2
              style={{
                overflow: 'auto',
                height: 'auto',
                //width:'800px',
                display: 'flex',
                alignItems: 'center',

                justifyContent: 'center'
              }}
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              // className={classes.modal}
              open={this.state.visible}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500
              }}
            >
              <Fade in={this.state.visible}>
                {/* <IDCard handleClose={this.handleClose} data={this.state.digitalheathcard} cardId={this.state.cardId} network={this.state.network}
                        contactNumber={this.state.contactNumber} showEmpId={this.state.showEmpId}/> */}
              </Fade>
            </Modal2>
          ) : null}

          {/* ---- Find Provider Modal popup ------ */}
          <Modal size="lg" show={this.state.ModalpopUp} onHide={() => this.setState({ ModalpopUp: false })} backdrop="static" >
                <Modal.Header className="header-container" style={{background:'transparent'}}>
                    
                    <div className="modalTitle">  <img src={findaProviderIcon} style={{margin:'0', width:'35px'}} /> Find Another Provider</div>
                    <div className="icons-header">                        
                        <CloseIcon style={{ color: '#818181', marginLeft: 10, cursor: 'pointer' }} onClick={() => this.setState({ ModalpopUp: false, documentmessage: "" })} />
                    </div>
                </Modal.Header>
                <iframe height="500px" width="100%" src={this.state.findproviderUrl}></iframe>

            </Modal>



        </Sidebar>
      </div>
    )
  }
  JumpToAI = () => {
    if (this.props.ChatWindow)
      this.props.history.push('/')
    else
      this.props.history.push('/AiAssistant')
  }
  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      open: !this.state.open
    });
  };
  handleClickAway = () => {
    this.setState({
      open: false,
      // anchorEl:null
    });
  };

  dateformat(date) {
    const enrollmentDate = this.getDateInUTC(date, true)
    let nowdate = this.getDateInUTC(new Date(), true)

    var day = moment(enrollmentDate).format('DD')

    var mon = moment(enrollmentDate).format('MM')

    var year = moment(enrollmentDate).format('YYYY')

    var date = mon + "/" + day + "/" + year

    var todayDate = moment(nowdate).format('MM/DD/YYYY')
    let hr = moment(enrollmentDate).format('hh')

    if (date !== todayDate) {

      return moment(date).format('MMM DD')

    } else {


      if (hr == '00') {
        return moment(enrollmentDate).format('m') + 'mins'
      }
      if (hr > 12) {
        return moment(enrollmentDate).format('hh:mm A')
      } else {
        return moment(enrollmentDate).format('hh:mm A')
      }

    }

  }
  getDateInUTC = (date, getInMillisecs) => {

    if (date) {

      let newDateTime = new Date(date)


      return new Date(newDateTime)

    }

    return date

  }
  handleClose = () => {
    this.setState({
      visible: false,
      // isFlipped: false
    })
  }

  onClickUpdateNotificationStatus = (id, url) => {
    console.log("Notification ID ====", id, url)
    let obj = { "notificationId": id }
    axios.post(process.env.REACT_APP_BASE_URL + "v8/Twillio/" + "updateNotificationStatus", obj).then(response => {
      if (url == 'ProgramInformation') {
        window.location.href = '/MemberInformation'
      }
      if (url == 'Needs' || url == 'MyNeeds') {
        window.location.href = '/MemberNeeds'
      }
      if (url == 'MobileTransaction') {
        // window.location.href='/PaymentWallet'
        this.setState({ noticeModal: true })
      }
    })
  }
  sethealthcarddata() {
    if (
      this.state.plainId == '1001' ||
      this.state.plainId == '1002' ||
      this.state.plainId == '1003' ||
      this.state.plainId == '1004' ||
      this.state.plainId == '1005' ||
      this.state.plainId == '1006'
      || this.state.plainId == '1017'
      || this.state.plainId == '1018' || this.state.plainId == '1019' || this.state.plainId == '1020' || this.state.plainId == '1021' || this.state.plainId == '1022'
    ) {
      this.setState({
        // network: 'PHCS',
        // channel: 'NEO',
        // contactNumber: '(888) 366-6243',
        // // cardId: '1kNEOUHSR071820E072020',
        // cardId: 'UHSR101920E101220',
        showEmpId: false
      })
    }

    if (
      this.state.plainId == '7001' ||
      this.state.plainId == '7002' ||
      this.state.plainId == '7003' ||
      this.state.plainId == '7004' ||
      this.state.plainId == '7005' ||
      this.state.plainId == '7006'
      || this.state.plainId == '7017'
      || this.state.plainId == '7018' || this.state.plainId == '7019' || this.state.plainId == '7020' || this.state.plainId == '7021' || this.state.plainId == '7022'
    ) {
      this.setState({
        // network: 'PHCS',
        // channel: 'Tutela',
        // contactNumber: '(800) 987-1990',
        // // cardId: '7kTTUHSR071720E072020',
        // cardId: 'UHSR101920E101220',
        showEmpId: true
      })
    }

    if (
      this.state.plainId == '8001' ||
      this.state.plainId == '8002' ||
      this.state.plainId == '8003' ||
      this.state.plainId == '8004' ||
      this.state.plainId == '8005' ||
      this.state.plainId == '8006'
      || this.state.plainId == '8017'
      || this.state.plainId == '8018' || this.state.plainId == '8019' || this.state.plainId == '8020' || this.state.plainId == '8021' || this.state.plainId == '8022'
    ) {
      this.setState({
        // network: 'PHCS',
        // channel: 'HST',
        // contactNumber: '(888) 942-4725',
        // // cardId: '8kHSTUHSR071720E072020',
        // cardId: 'UHSR101920E101220',
        showEmpId: true
      })
    }

    if (
      this.state.plainId == '9001' ||
      this.state.plainId == '9002' ||
      this.state.plainId == '9003' ||
      this.state.plainId == '9004' ||
      this.state.plainId == '9005' ||
      this.state.plainId == '9006'
      || this.state.plainId == '9017'
      || this.state.plainId == '9018' || this.state.plainId == '9019' || this.state.plainId == '9020' || this.state.plainId == '9021' || this.state.plainId == '9022'
    ) {
      this.setState({
        // network: 'PHCS',
        // channel: 'Parish',
        // contactNumber: '(855) 030-4941',
        // // cardId: '9kPBUHSR071720E072020',
        // cardId: 'UHSR101920E101220',
        showEmpId: true
      })
    }

    if (
      this.state.plainId == '10001' ||
      this.state.plainId == '10002' ||
      this.state.plainId == '10003' ||
      this.state.plainId == '10004' ||
      this.state.plainId == '10005' ||
      this.state.plainId == '10006'

      || this.state.plainId == '10017'
      || this.state.plainId == '10018' || this.state.plainId == '10019' || this.state.plainId == '10020' || this.state.plainId == '10021' || this.state.plainId == '10022'
    ) {
      this.setState({
        // network: 'PHCS',
        // channel: 'CHS',
        // planIds: [10001, 10002, 10003, 10004, 10005, 10006],
        // contactNumber: '(888) 792-4722',
        // // cardId: '9kPBUHSR071720E072020',
        // cardId: 'UHSR101920E101220',
        showEmpId: true
      })
    }

    if (
      this.state.plainId == '11001' ||
      this.state.plainId == '11002' ||
      this.state.plainId == '11003' ||
      this.state.plainId == '11004' ||
      this.state.plainId == '11005' ||
      this.state.plainId == '11006'

      || this.state.plainId == '11017'
      || this.state.plainId == '11018' || this.state.plainId == '11019' || this.state.plainId == '11020' || this.state.plainId == '11021' || this.state.plainId == '11022'
    ) {
      this.setState({
        // network: 'PHCS',
        // channel: 'CHS-Plus',
        // contactNumber: '(888) 792-4722',
        // // cardId: '9kPBUHSR071720E072020',
        // cardId: 'UHSR101920E101220',
        showEmpId: true
      })
    }

    if (
      this.state.plainId == '12001' ||
      this.state.plainId == '12002' ||
      this.state.plainId == '12003' ||
      this.state.plainId == '12004' ||
      this.state.plainId == '12005' ||
      this.state.plainId == '12006'

      || this.state.plainId == '12017'
      || this.state.plainId == '12018' || this.state.plainId == '12019' || this.state.plainId == '12020' || this.state.plainId == '12021' || this.state.plainId == '12022'
    ) {
      this.setState({
        // network: 'PHCS',
        // channel: 'BIG',
        // contactNumber: '(855) 809-0110',
        // // cardId: '12kBGUHS071720E072020',
        // cardId: 'UHSR101920E101220',
        showEmpId: true
      })
    }

    if (
      this.state.plainId == '6001' ||
      this.state.plainId == '6002' ||
      this.state.plainId == '6003' ||
      this.state.plainId == '6004' ||
      this.state.plainId == '6005' ||
      this.state.plainId == '6006'

      || this.state.plainId == '6017'
      || this.state.plainId == '6018' || this.state.plainId == '6019' || this.state.plainId == '6020' || this.state.plainId == '6021' || this.state.plainId == '6022'
    ) {
      this.setState({
        // network: 'AFMC',
        // channel: 'AFA',
        // contactNumber: '(855) 229-0257',
        // // cardId: '6kAFAUHSR071820E072020',
        // cardId: '6kAFAUHSR101920E101220',
        showEmpId: true
      })
    }

    if (this.state.plainId == '1011') {
      this.setState({
        // network: 'Smartshare',
        // channel: 'NEO',
        // contactNumber: '(888) 366-6243',
        // cardId: 'SSR101920E101220',
        showEmpId: false //not sure for other than 1011 plans
      })
    }





    if (this.state.plainId == '6011') {
      this.setState({
        // network: 'Smartshare',
        // channel: 'UHF',
        // contactNumber: '(888) 791-4722',
        // cardId: 'SSR101920E101220',
        showEmpId: true
      })
    }

    if (this.state.plainId == '13001' || this.state.plainId == '13002' || this.state.plainId == '13003' || this.state.plainId == '13004'
      || this.state.plainId == '13005' || this.state.plainId == '13006' || this.state.plainId == '13017'
      || this.state.plainId == '13018' || this.state.plainId == '13019' || this.state.plainId == '13020' || this.state.plainId == '13021' || this.state.plainId == '13022') {
      this.setState({
        // network: 'PHCS',
        // channel: 'Aspire',
        // contactNumber: '(888) 992-4789',
        // cardId: 'UHSR101920E101220',
        showEmpId: true
      })
    }

    // if (this.state.plainId == '7011' || this.state.plainId == '8011' || this.state.plainId == '12011'  || this.state.plainId == '9011' || this.state.plainId == '10011'  || this.state.plainId == '11011' || this.state.plainId == '13011'  ) {
    //   this.setState({
    //     network: 'Smartshare',
    //     channel: 'PHCS',
    //     contactNumber: '(855) 809-0110',
    //     cardId: 'UHSR101920E101220',
    //     showEmpId: true
    //   })
    // }

    if (this.state.plainId == '7011') {
      this.setState({
        // network: 'Smartshare',
        // channel: 'PHCS',
        // contactNumber: '(800) 987-1990',
        // cardId: 'UHSR101920E101220',
        showEmpId: true //not sure for other than 1011 plans
      })
    }

    if (this.state.plainId == '8011') {
      this.setState({
        // network: 'Smartshare',
        // channel: 'PHCS',
        // contactNumber: '(888) 942-4725',
        // cardId: 'SSR101920E101220',
        showEmpId: true //not sure for other than 1011 plans
      })
    }

    if (this.state.plainId == '9011') {
      this.setState({
        // network: 'Smartshare',
        // channel: 'PHCS',
        // contactNumber: '(855) 030-4941',
        // cardId: 'SSR101920E101220',
        showEmpId: true //not sure for other than 1011 plans
      })
    }

    if (this.state.plainId == '10011') {
      this.setState({
        // network: 'Smartshare',
        // channel: 'PHCS',
        // contactNumber: '(888) 792-4722',
        // cardId: 'SSR101920E101220',
        showEmpId: true //not sure for other than 1011 plans
      })
    }

    if (this.state.plainId == '11011') {
      this.setState({
        // network: 'Smartshare',
        // channel: 'PHCS',
        // contactNumber: '(888) 792-4722',
        // cardId: 'SSR101920E101220',
        showEmpId: true //not sure for other than 1011 plans
      })
    }

    if (this.state.plainId == '12011') {
      this.setState({
        // network: 'Smartshare',
        // channel: 'PHCS',
        // contactNumber: '(855) 809-0110',
        // cardId: 'SSR101920E101220',
        showEmpId: true //not sure for other than 1011 plans
      })
    }

    if (this.state.plainId == '13011') {
      this.setState({
        // network: 'Smartshare',
        // channel: 'PHCS',
        // contactNumber: '(888) 992-4789',
        // cardId: 'SSR101920E101220',
        showEmpId: true //not sure for other than 1011 plans
      })
    }
    if (this.state.plainId == '20120' || this.state.plainId == '20140' || this.state.plainId == '20160' || this.state.plainId == '20151'
      || this.state.plainId == '20152' || this.state.plainId == '20220' || this.state.plainId == '20240' || this.state.plainId == '20260' || this.state.plainId == '20251'
      || this.state.plainId == '20252' || this.state.plainId == '20320' || this.state.plainId == '20340' || this.state.plainId == '20360' || this.state.plainId == '20351'
      || this.state.plainId == '20352' || this.state.plainId == '20420' || this.state.plainId == '20440' || this.state.plainId == '20460' || this.state.plainId == '20451'
      || this.state.plainId == '20452' || this.state.plainId == '20520' || this.state.plainId == '20540' || this.state.plainId == '20560' || this.state.plainId == '20551'
      || this.state.plainId == '20552') {
      this.setState({
        showEmpId: true
      })
    }

    if ((localStorage.getItem("CLIENT_ID") == '6548' || localStorage.getItem("CLIENT_ID") == 6548) || (localStorage.getItem("CLIENT_ID") == '4367' || localStorage.getItem("CLIENT_ID") == 4367)) {
      this.setState({
        showHealthCard: true,
        visibleHealthy: true,
        visible: false,

      });
    } else {
      this.setState({
        showHealthCard: true,
        visible: true,
        visibleHealthy: false,
        loader: false
      })
    }

  }
}
export default withRouter(Header);