import logo from './logo.svg';
import { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Link, Route , Redirect, Switch} from 'react-router-dom';
import Siginin from './Components/Auth/Signin';
import Routes from './Routes';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import Footer from './Components/Footer/Footer';
import firebase from './firebase';
Amplify.configure(awsconfig);

function App() {
  const [isLogged, setIsLogged] = useState(false);
  useEffect(() => {
    Auth.currentSession().then((session) => {
      setIsLogged(true);
      localStorage.setItem("isLogin", true);
    }).catch((error) => {
      setIsLogged(false)
      localStorage.setItem('isLogin', false)
    })

    const msg = firebase.messaging.isSupported() ? firebase.messaging() : null;
    if (msg) {
      msg.requestPermission().then(()=>{
        return msg.getToken()
      }).then(token=>{
        localStorage.setItem("TOKEN_TYPE",'web')
        localStorage.setItem("firebaseToken",token)
      });
    }
  },[])


  if(localStorage.getItem("isLogin")==="true" ||localStorage.getItem("isLogin")===true )
  return(<div className="background-full-size">
      <Routes/>
  </div>)
  else
  return (
    <div>
      <BrowserRouter>
          <Switch>
                <Route exact path="/login" component={Siginin} />
                <Redirect from="/" to="/login" />
          </Switch>
          <Footer/>
      </BrowserRouter>

    </div>
  );
}

export default App;
