import { Component } from 'react';
import NotificationIcon from '../../Assets/my_notifications_icon_active_old.svg';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {Link} from 'react-router-dom'
import DashboardIcon from './Images/dashboard-icon-active (1).svg'
import DocumentsIcon from './Images/documents-icon-active.svg'
import PaymentIcon from '../../Assets/payment_wallet_icon_active.svg';
import findaproviderIcon from './Images/find-a-provider-icon-active.svg'
// import MembershipIcon from '../../Assets/memberInfoIcon.jpg'
import MembershipIcon from './Images/membership-card-icon-active.svg'
import ProgramInfoIcon from './Images/program-info-icon-active.svg'
import MyNeedsIcon from '../../Assets/my-needs-icon-active-old.svg';
import NoticesIcon from './Images/notices-icon-active.svg';
import MyTransactionIcon from './Images/my_transactions_icon_active.svg'
import MedicalIcon from './Images/medical-q-icon-active.svg'
import ContactIcon from './Images/contact-info-icon-active.svg'
import FAQsIcon from './Images/find-a-provider-icon-active.svg'
import logoutIcon from './Images/logout-icon-active.svg'
import LogoUHS from './Images/uhs-logo.svg';
import './Drawer.css';
export default class LeftDrawer extends Component{
    constructor(props){
        super(props)
        this.state={
            username:localStorage.getItem('subscriberName'),
            id:false,
            link:'http://www.mdlive.com/FlexCare',
            showmyneeds:false,
            dashboardCards : [],
            width:'',
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    } 
    updateWindowDimensions() {
      this.setState({ width: window.innerWidth });
    }
    componentDidMount(){
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);

    }
    closeparentdrawer(enable) {
        if(enable){
          this.props.drawercloseparent(false);
        } else {
          this.props.openTempModal();
        }
      }

      openExpenseCard() {
        this.props.drawercloseExpenseOpen(false);
      }

      opendocuments(){
        this.props.documentsopen(false);
      }

    handleSignout = () => {
    localStorage.clear();
    window.location.reload();
    }

    opennotices(enable){
      if(enable){
        window.open("https://www.universalhealthfellowship.org/notices/");
      } else {
        this.props.openTempModal();
      }
    }

    opencontacts(){
        this.props.contactopen(false);
    }

    openprovider(enable) {
      if(enable){
        var windowReference = window.open();
      } else {
        this.props.openTempModal();
      }

    }

    openfaqs(enable){
      if(enable){
        window.open("https://www.universalhealthfellowship.org/FAQs/")
      } else {
        this.props.openTempModal();
      }
    }

    openhealthcard(){
        this.setState({id:true})
    }

    opentelemed(){
        window.open(this.state.link)
    }

    render(){
        return(
            <div class="leftdrawer">
               <img src={LogoUHS} class="mob_ld_user_div_logo_img"/>
                 <div className="ld_user_div">
                    <AccountCircleIcon/>
                    <div className="ld_username">Hello, {this.props.username} </div>
                </div>
                <Link to="/" style={{textDecoration :'none',fontSize:'16px',fontWeight:500,  color:'black'}} class={`ld_menu_container ${this.props.header== "Dashboard" && "ld_menu_container_selected"}`}>
                    <div class="ld_image_div_container">
                    <img src={DashboardIcon} style={{marginRight:'20px'}}/>
                    </div>
                    <div clas="ld_menu_name">Dashboard</div>
              </Link>



              <div onClick={()=>window.location.href="/MemberInformation"} style={{textDecoration :'none',fontSize:'16px',fontWeight:500,  color:'black'}} class={`ld_menu_container ${this.props.header== "Member Information" && "ld_menu_container_selected"}`}>
                    <div class="ld_image_div_container">
                    <img src={MembershipIcon} style={{marginRight:'20px', width: 22,}}/>
                    </div>
                    <div clas="ld_menu_name">Member Information</div>
              </div>
              
              <div onClick={()=>window.location.href="/MemberNeeds"} style={{textDecoration :'none',fontSize:'16px',fontWeight:500,  color:'black'}} class={`ld_menu_container ${this.props.header== "Member Needs" && "ld_menu_container_selected"}`}>
                    <div class="ld_image_div_container">
                    <img src={MyNeedsIcon} style={{marginRight:'20px', width: 22,}}/>
                    </div>
                    <div clas="ld_menu_name">Member Needs</div>
              </div>

                <div onClick={()=>this.props.findProviderOpen()} style={{textDecoration :'none',fontSize:'16px',fontWeight:500,  color:'black'}} class={`ld_menu_container ${this.props.header== "Find Another Provider" && "ld_menu_container_selected"}`}>
                    <div class="ld_image_div_container">
                      <img src={FAQsIcon} style={{width: 22, marginRight:'20px'}}/>
                    </div>
                    <div clas="ld_menu_name">Find Another Provider</div>
                  </div>

                  {/* <Link to="/PaymentWallet" style={{textDecoration :'none',fontSize:'16px',fontWeight:500,  color:'black'}} class={`ld_menu_container ${this.props.header== "Payment Wallet" && "ld_menu_container_selected"}`}>
                    <div class="ld_image_div_container">
                    <img src={PaymentIcon} style={{width: 22, marginRight:'20px'}}/>
                    </div>
                    <div clas="ld_menu_name">Payment Wallet</div>
                  </Link> */}
                  <div onClick={()=>window.location.href="/MyNotification"} style={{textDecoration :'none',fontSize:'16px',fontWeight:500,  color:'black'}} class={`ld_menu_container ${this.props.header== "My Notification" && "ld_menu_container_selected"}`}>
                    <div class="ld_image_div_container">
                    <img src={NotificationIcon} style={{width: 28, marginRight:'20px'}}/>
                    </div>
                    <div clas="ld_menu_name">My Notification</div>
                  </div>
                  <div onClick={()=>window.location.href="/Announcements"} style={{textDecoration :'none',fontSize:'16px',fontWeight:500,  color:'black'}} class={`ld_menu_container ${this.props.header== "Announcements & Notices" && "ld_menu_container_selected"}`}>
                    <div class="ld_image_div_container">
                    <img src={NoticesIcon} style={{width: 22, marginRight:'20px'}}/>
                    </div>
                    <div clas="ld_menu_name">Announcements & Notices</div>
                  </div>
                  <div   style={{textDecoration :'none',fontSize:'16px',fontWeight:500}} class={`ld_menu_container ${this.props.name== "Dashboard" && "ld_menu_container_selected"}`} onClick={()=>this.props.documentsopen()}>  
                    <div class="ld_image_div_container">
                    <img src={DocumentsIcon} style={{width: 22, marginRight:'20px'}}/>
                    </div>
                    <div clas="ld_menu_name">Documents</div>
                  </div>
           

                <div   style={{textDecoration :'none',fontSize:'16px',fontWeight:500}} class={`ld_menu_container ${this.props.name== "Sign Out" && "ld_menu_container_selected"}`} onClick={()=>this.handleSignout()}>
                <div class="ld_image_div_container">
                    <img src={logoutIcon} style={{marginRight:'20px'}}/>
                    </div>
                    <div clas="ld_menu_name">Sign Out</div>
                </div>
                {/* <div class="mob_ld_menu_name_version">Version 3.0.4</div> */}
            </div>
        )
    }
}