import React,{Component} from 'react';
import TelemedIcon from './Images/telemed_icon_active.svg';
import './Drawer.css';

export default class RightDrawer extends Component{
        constructor(props){
                super(props)
                this.state={
                        link:'',
                }
        }
        opentelemed(){
                window.open(this.state.link)
        }
        componentDidMount(){
             
        }
    render(){
        return(
            <div class="rightdrawer">

            <div class="rightdrawer_card text-center" onClick={()=>this.opentelemed()}>
            <img src={TelemedIcon} style={{height:'84px',width:'84px'}}/>
                    <div class="rightdrawer_card_name">Telemed</div>
            </div>


            </div>
        )
    }
}