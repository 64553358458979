import { Component } from 'react';
import {Form, Modal} from "react-bootstrap";
import { Fab } from '@material-ui/core';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import AskCaryn from '../../Assets/AskCaryn.png';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DocumentIcon from '../../Assets/documents-icon-active.svg';
import carynIcon from '../../Assets/carynIcon.jpg';
import CloseIcon from '@material-ui/icons/Close';
import CryptoJS from "crypto-js";
export default class Documents extends Component{
    constructor(props) {
      super(props)
      this.state = {
        documentmessage:'',
        sidebarOpen: false,
        dockval: false,
        rightslider: false,
        rightdockval: false,
        showHealthCard: false,
        bigcard: '',
        smallcard: '',
        rightcard: '',
        x: false,
        yellowPopup:true,
        documentsmodal:false,
        contactmodal:false,
         mqmoduleAlert:null,
         mqloader:false,
         myneedsfooter:false,
        contactCardData : null,
        documentCardData : null,
        tempNotAvailableModal : false,
        notificationData: [],
        unReadData:[],
        notificationCount: 0,
        anchorEl: null,
        open: false,
        show:false,
        memberservices: true,
        memberapps: false,
        loader:true,
        width:'',
        urlKB:"",
        ModalpopUp:false,
        enlarge:false,
      }
      
    }
    render(){
        return (<div>
                <div className="cardwallet_back_notification documentsCard">
                        <div className="NotificationCard-BG text-center">
                            <div className="cardwallet_onover_backdivMain">
                                <img src={DocumentIcon} className="cardwaalet_img_backside" style={{width:'25px'}}/>
                                <div className="notification_label_backside">Documents</div>
                                <div style={{paddingLeft:0}} class="crossButton"> {this.props.modal ?<CloseIcon onClick={()=>this.props.closeModal()} style={{color:'#818181', cursor:"pointer"}}/> : <MoreVertIcon style={{color:'#818181'}}/>}</div>
                            </div>
                        </div>
                        <form onSubmit={this.openAskCaryn}>
                        <div  style={{backgroundColor:'#CDACCF', height:53}}>
                            <div style={{paddingTop:7, paddingLeft:20, justifyContent:'space-between', display:'flex'}}>
                            <Form.Control value={this.state.documentmessage} placeholder="Ask a question..."  className="inputBox" onChange={e=>this.setState({documentmessage: e.target.value})} />
                                <Fab style={{backgroundColor:"#543379", width:42, height:39, right:32}} onClick={()=>this.openAskCaryn(false)}>
                                <img src={carynIcon} width="40px"/>
                                </Fab>
                            </div> 
                        </div>
                        </form>
                        <div class="documents_infomiddiv text-left">
                            {/* <div class="documentscardnew_welcome_div" onClick={() => this.openwelcome()}>Welcome Booklet</div> */}
                            <div class="documentscardnew_welcome_div" onClick={() => this.opensharingguidlines()}>Sharing Guidelines</div>
                            <div class="documentscardnew_welcome_div" onClick={() => this.opensecond()}>Member Responsibilities</div>
                            <div class="documentscardnew_welcome_div" onClick={() => this.openthird()}>Statement of Shared Faith and Beliefs</div>
                            <div class="documentscardnew_welcome_div" onClick={() => this.getstartedHealth()}>Get Started with Health Sharing</div>
                            <div class="documentscardnew_welcome_div" onClick={() => this.open5question()}>5 Questions about Health Sharing</div>
                            <div class="documentscardnew_welcome_div" onClick={() => this.tipsTelemedicin()}>Tips on Telemedicine</div>
                            <div class="documentscardnew_welcome_div" onClick={() => this.great4reason()}>4 Great Reasons to share UHS</div>
                            <div class="documentscardnew_welcome_div" onClick={() => this.quick3facts()}>3 Quick Facts for Doctors</div>
                        </div> 
                </div>
                {/* ---- AIKB Modal popup ------ */}
                <Modal size={this.state.enlarge?"xl":"lg"} show={this.state.ModalpopUp} onHide={() => this.setState({ModalpopUp: false, documentmessage:""})} backdrop="static" >
                        <Modal.Header className="header-container">
                              <div className="logo"> 
                                  <img src={AskCaryn} width="70%"/>
                              </div>
                              <div className="caption">Your AI-Powered Assistant</div>
                              <div className="icons-header">
                                {this.state.enlarge?<FullscreenExitIcon style={{color:'white', cursor:'pointer'}} onClick={()=>this.setState({enlarge:false})}/>:<FullscreenIcon style={{color:'white', cursor:'pointer'}} onClick={()=>this.setState({enlarge:true})}/>}
                                <CloseIcon style={{color:'white', marginLeft:10, cursor:'pointer'}} onClick={() => this.setState({ModalpopUp: false, documentmessage:""})}/>
                              </div>
                        </Modal.Header>     
                                 <iframe height={this.state.enlarge?"550px":"500px"} width="100%" src={this.state.urlKB}></iframe>
                            
                </Modal>
        </div>)
    }
    openAskCaryn=(e)=>{
        e&&e.preventDefault();
        let urlData={
            "memberId": localStorage.getItem('Member_EMPID'),
            "query": this.state.documentmessage,
            "ChannelUId":"memberportal",
          }
        let key = CryptoJS.enc.Utf8.parse("000102030405060708090a0b0c0d0e0f");
        let iv = CryptoJS.enc.Utf8.parse("4t7w9z$C&F)J@NcR");
        let input = CryptoJS.enc.Utf8.parse(JSON.stringify(urlData));
        var dataEncrypt = CryptoJS.AES.encrypt(input, key, { keySize: 256 / 32, iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }).toString();
        this.setState({urlKB:`https://inf-labs.com/?isnav=false&data=${encodeURIComponent(dataEncrypt)}`},()=>{
            this.setState({ModalpopUp:true})
          })
        //window.open(`https://inf-labs.com/?isnav=true&data=${encodeURIComponent(dataEncrypt)}`)

        //-- If it is open from leftDrawer
        if(this.props.modal){
            this.props.closeModal();
        }
    }
    openProvider=()=>{
        window.open("http://findprovider.universalhealthfellowship.org/")
    }
    openwelcome=()=>{
        window.open("https://carynhealth-memberportal-dev-documents.s3.us-east-2.amazonaws.com/AFA/UHS+Welcome+Booklet+-+6kAFAUHSR0626E060920.pdf");
   }

   opensecond=()=>{
       window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHS+Member+Responsibilities.pdf")
   }

   openthird=()=>{
       window.open(" https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHF-Statement-of-Shared-Faith-%26-Beliefs.pdf")
       // window.open("https://carynhealth-memberportal-dev-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHF+Statement+of+Faith+%26+Beliefs.pdf")
   }

   getstartedHealth=()=>{
       window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+How+do+I+Get+Started+with+Health+Sharing.pdf")
   }

   open5question=()=>{
       window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+5+Questions+about+Health+Sharing.pdf")
   }

  tipsTelemedicin=()=>{
       window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+Tips+on+Telemedicine.pdf")
   }

   great4reason=()=>{
       window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+4+Great+Reasons+to+share+Universal+HealthShare+with+Your+Friends.pdf")
   }

   quick3facts=()=>{
       window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+Doctors!+3+Quick+Facts+about+Health+Sharing.pdf")
   }

   opensharingguidlines=()=> {
     window.open("https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHS-Sharing-Program-Guidelines.pdf")
     }
}

const stylesheet={
    searchInputBox:{
        borderRadius:40, 
        paddingRight:40, 
        paddingLeft:20,
        width:'20vw', 
        borderColor:'#420045', 
        borderWidth:'revert',
        outline:'none'
    }
  }