import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const AlertDialog = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        props.handleClose()
    };

    return (
        <div>

            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <DialogContent>
                    <p className='label-head'>
                        {props.content}
                        <p className="label-head">{props.confirm?"You are all set!":`Sorry! We could not find an active registration for ${props.email}`} </p>
                        <p className="label-head">{props.confirm?'If you can’t remember your password, you can reset it by clicking the “Forgot your password?”':"If it’s been more than one business day since you signed up, please contact our Account Activation line: (888) 308 0024"}  </p>
                    </p>

                    <div style={{ display: 'flex',justifyContent: 'space-between' }}>
                   {props.confirm ? <Button onClick={() => props.gotoResetPassword()} style={{ width: 'auto', minWidth: '70px', height: '40px', margin: '0 0px 15px 0', backgroundColor: '#7064db', color: 'white' }}>
                        Forgot your password
                    </Button>:<div></div>}
                        <Button onClick={() => props.handleClose()} style={{ width: 'auto', minWidth: '70px', height: '35px', backgroundColor: '#fb6647', color: 'white' }}>
                            Close </Button>
                    </div>

                </DialogContent>

            </Dialog>
        </div>
    );
}

export default AlertDialog;