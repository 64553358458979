import { Component } from 'react';
import Sidebar from 'react-sidebar';
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import customStyle from '../Styles/stylesheet_UHS';
import Header from '../Header/Header';
import './Home.css';
import { Grid } from "@material-ui/core";
import { Form, Modal } from "react-bootstrap";
import { Fab } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import WelcomeImage from '../../Assets/welcome-back.png';
import NotificationIcon from '../../Assets/my_notifications_icon_active.svg';
import NoticesIcon from '../../Assets/notices-icon-active.svg';
import DocumentIcon from '../../Assets/documents-icon-active.svg';
import MemberInfoIcon from '../../Assets/memberInfoIcon.jpg';
import carynIcon from '../../Assets/carynIcon.jpg';
import Documents from '../Documents/Documents';
import ProviderDetails from '../ProviderDetails/ProviderDetails';
import notifNoticesIcon from '../../Assets/notification/notification_notice_icon.svg';
import MyNeedsIcon from '../../Assets/my-needs-icon-active.svg';
import findaProviderIcon from '../../Assets/find-a-provider-icon-active.svg';
import PaymentWalletIcon from '../../Assets/payment_wallet_icon_active.svg';
import MyNotification from '../MyNotification/MyNotification';
import Notices from '../Notices/Notices';
import CryptoJS from "crypto-js";
import Footer from '../Footer/Footer'
import CloseIcon from '@material-ui/icons/Close';

export default class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            documentmessage: '',
            sidebarOpen: false,
            dockval: false,
            rightslider: false,
            rightdockval: false,
            showHealthCard: false,
            bigcard: '',
            smallcard: '',
            rightcard: '',
            x: false,
            name: '',
            yellowPopup: true,
            documentsmodal: false,
            contactmodal: false,
            mqmoduleAlert: null,
            mqloader: false,
            myneedsfooter: false,
            contactCardData: null,
            documentCardData: null,
            tempNotAvailableModal: false,
            notificationData: [],
            unReadData: [],
            notificationCount: 0,
            anchorEl: null,
            open: false,
            show: false,
            memberservices: true,
            memberapps: false,
            loader: true,
            width: '',
            findproviderUrl:"",
            ModalpopUp: false,
        }

    }
    componentDidMount() {
        var authUser = localStorage.getItem('authUser');
        var user = JSON.parse(authUser)
        this.setState({ name: user.attributes.name })
    }
    openAskCaryn = () => {
        let urlData = {
            "memberId": localStorage.getItem('Member_EMPID'),
            "query": this.state.documentmessage,
            "ChannelUId": "memberportal",
        }
        let key = CryptoJS.enc.Utf8.parse("000102030405060708090a0b0c0d0e0f");
        let iv = CryptoJS.enc.Utf8.parse("4t7w9z$C&F)J@NcR");
        let input = CryptoJS.enc.Utf8.parse(JSON.stringify(urlData));
        var dataEncrypt = CryptoJS.AES.encrypt(input, key, { keySize: 256 / 32, iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }).toString();
        window.open(`https://inf-labs.com/?isnav=true&data=${encodeURIComponent(dataEncrypt)}`)
    }
    openFindProviderModal =()=>{
        // this.setState({findproviderUrl:"http://findprovider.universalhealthfellowship.org/"},()=>{
        //     this.setState({ModalpopUp:true})
        //   })
        window.open("http://findprovider.universalhealthfellowship.org/");
    }
    render() {
        return (<div>
            <Header header="Dashboard" username="John Doe" />
            <div className="web_container">
                <div className="web_middle_seconddiv">
                    <img src={WelcomeImage} class="web_welcome_back" />
                    <div class="web_top_username">{this.state.name && this.state.name}!</div>
                </div>
            </div>
            <div className="mainWrapper">
                <Grid container className="MobileCenter">
                    <Grid item md={3}>
                        <MyNotification />
                    </Grid>
                    <Grid item md={3}>
                        <Notices />
                    </Grid>
                    <Grid item md={3}>
                        <Documents />
                    </Grid>
                    <Grid item md={3}>
                        <ProviderDetails />
                    </Grid>

                    <Grid item md={6}>
                        <Grid container>
                            <Grid item md={3}>
                                <div class="Card-BG text-center smallCardBox" onClick={() => window.location.href = '/MemberInformation'}>
                                    <img src={MemberInfoIcon} class="maincard_image_icon" />
                                    <p class="captiontextdiv ">Member Information</p>
                                </div>
                            </Grid>
                            <Grid item md={3}>
                                <div class="Card-BG text-center smallCardBox" onClick={() => window.location.href = '/MemberNeeds'}>
                                    <img src={MyNeedsIcon} class="maincard_image_icon" />
                                    <p class="captiontextdiv ">Member Needs</p>
                                </div>
                            </Grid>
                            <Grid item md={3}>
                                <div class="Card-BG text-center smallCardBox" onClick={()=>this.openFindProviderModal()}>
                                    <img src={findaProviderIcon} class="maincard_image_icon" />
                                    <p class="captiontextdiv ">Find Another Provider</p>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* <Grid item md="1.5">
                        <div class="Card-BG text-center" onClick={() => window.location.href = '/MemberInformation'}>
                            <img src={MemberInfoIcon} class="maincard_image_icon" />
                            <p class="captiontextdiv ">Member Information</p>
                        </div>
                        <div class="Card-BG text-center" onClick={() => window.open("http://findprovider.universalhealthfellowship.org/")}>
                            <img src={findaProviderIcon} class="maincard_image_icon" />
                            <p class="captiontextdiv ">Find Another Provider</p>
                        </div>

                    </Grid> */}
                    {/* <Grid item md="1.5">
                        <div class="Card-BG text-center" onClick={() => window.location.href = '/MemberNeeds'}>
                            <img src={MyNeedsIcon} class="maincard_image_icon" />
                            <p class="captiontextdiv ">Member Needs</p>
                        </div> */}
                    {/* <div class="Card-BG text-center" onClick={()=>this.props.history.push('/PaymentWallet')}>
                    <img src={PaymentWalletIcon} class="maincard_image_icon"/>
                    <p class="captiontextdiv ">Payment Wallet</p>
                </div> */}
                    {/* </Grid> */}
                </Grid>

            </div>

            {/* ---- Find Provider Modal popup ------ */}
            <Modal size="lg" show={this.state.ModalpopUp} onHide={() => this.setState({ ModalpopUp: false })} backdrop="static" >
                <Modal.Header className="header-container" style={{background:'transparent'}}>
                    
                    <div className="modalTitle">  <img src={findaProviderIcon} style={{margin:'0', width:'35px'}} /> Find Another Provider</div>
                    <div className="icons-header">                        
                        <CloseIcon style={{ color: '#818181', marginLeft: 10, cursor: 'pointer' }} onClick={() => this.setState({ ModalpopUp: false, documentmessage: "" })} />
                    </div>
                </Modal.Header>
                <iframe height="500px" width="100%" src={this.state.findproviderUrl}></iframe>

            </Modal>

            <Footer></Footer>
        </div>)
    }
}

