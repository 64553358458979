import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import { Modal, Row, Container, Col, Button, Card } from 'react-bootstrap'
// import AccordionViewModal from './AccordionViewModal'
import '../MemberNeeds/MemberNeeds.css'
import moment from "moment";
const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    padding:'0px',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);



const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#ffffff',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 120,
    paddingLeft:'5px',
    paddingRight:'5px',
    '&$expanded': {
      minHeight: 125,
    },


  },


  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);
export default class  SimpleAccordion extends Component{
  constructor(props){
    super(props)
    this.state={
      expanded: '',
      formatedData: this.props.data,
      showModal:false,
      modalData:null,
      expansionPanelOpen :false
    }
    console.log("Accordion pros", this.state.formatedData);

    console.log("=========props data isss==========",this.props)
  }

  handleChange =  panel => (event, expanded) =>{
    console.log("expanded====",panel,expanded)
    this.setState({expanded:expanded ? panel : false})
}


  openModalInfo(dataIndex) {
    this.setState({
      showModal: true,
      modalData:this.state.formatedData[dataIndex]
    })
  }

  closeModalInfo() {
    this.setState({
      showModal: false
    })
  }
componentDidMount(){
  window.scrollTo(0, 0);
}
  //--- To get updated props on loadMore Btn click
  componentWillReceiveProps(nextProps) {
    this.setState({ formatedData: nextProps.data });
  }

   getDate(date){
    const isDateValid = moment(date)['_isValid'];
    if (isDateValid) {
        return moment(date).format('MMMM D, YYYY');
    }
    return date;
}

      numberWithCommas(x) {
          if(x){
            if(x.toString().startsWith('$')) {
              return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
              return '$' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          } else {
            return 'NA';
          }
      }

      floatconversion = (x) => {
          if(x){
            if(x.toString().startsWith('$')) {
              let substr = x.substr(1,x.length);
              return '$' + (parseFloat(substr).toFixed(2)).toString();
            } else {
              return x.toFixed(2);
            }
          } else {
            return undefined;
          }
      }
      getExpiryDate = date=>{
        const isDateValid = moment(date)['_isValid'];
        if (isDateValid) {
            return moment(date).format('MMMM D');
        }
        return date;
      }
      
    render(){
      return (
        <div className="mobileTable">
          {
           this.props.data && this.props.data.map((data, index) => (

              <Accordion expanded={this.state.expanded === 'panel'+index}  onChange={this.handleChange('panel'+index)}>
                <AccordionSummary
                  //expandIcon={<KeyboardArrowRightIcon  style={{marginLeft:'-25px'}}/>}
                  expandIcon={<ExpandMoreIcon className="collapseIcon" onClick={() => {
                    this.setState({
                      expansionPanelOpen: !this.state.expansionPanelOpen
                    });
                  }} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="panelSummary"
                >
              <Grid container >
                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">
                  Card ID
                      </div>
                  <div className="Expense-Number211">{data.cardIDNumber}</div>
                </Grid>

                <Grid item xs={6} className="itemWrap">
                
                  {
                    data.cardStatus=="Active" || data.cardStatus=="Denied"?
                    <span className={'status_button Audit'}>Active</span>
                    :
                    <span className={'status_button Logged'}>Processing</span>

                  }
                </Grid>

                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">Activation Date</div>
                  <div className="Expense-Number211">{this.getDate(data.cardActivationDate)}</div>
                </Grid>
                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">Expires on</div>
                  <div className="Expense-Number211">{this.getExpiryDate(data.expirationDate)}</div>
                </Grid>

                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">Member</div>
                  <div className="Expense-Number211">{data.memberFirstName} {data.memberLastName}</div>
                </Grid>
                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">Amount Authorized</div>
                  <div className="Expense-Number211">${data.amountAuthorized}</div>
                </Grid>

                <Grid item xs={12} className="itemWrap">
                  <div className="Expense-Number221">Provider</div>
                  <div className="Expense-Number211">
                  {data.providerName}
                  {/* {data.DateOfServiceVal} */}
                  </div>
                </Grid>

                {/* <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">Charged</div>
                  <div className="Expense-Number211">
                    ${data.charged_amount}
                   </div>

                </Grid> */}


              </Grid>
              </AccordionSummary>
                <AccordionDetails style={{paddingLeft:'26px'}}>

                <Grid container >
                <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">Procedure Information</div>
                  <div className="Expense-Number211">{data.procedureInformation}</div>
                </Grid>
                <Grid item xs={5} className="itemWrap">
                  <div className="Expense-Number221">Card Request Date</div>
                  <div className="Expense-Number211">{this.getDate(data.cardRequestDate)}</div>
                </Grid>
                {/* {
                  data.MoreDetail.EOSLabel == "" || data.MoreDetail.EOSLabel == " "
                  ?null
                  : */}
                  <Grid item xs={6} className="itemWrap">
                  <div className="Expense-Number221">Provider Address</div>
                  <div className="Expense-Number211" >{data.providerAddress1},{data.providerCity},{data.providerState}&nbsp;{data.providerPostalCode}</div>

                  {/* <div className="Expense-Number211 view_number" onClick={()=>this.openModalInfo(index)}>VIEW</div> */}
                </Grid>
                <Grid item xs={5} className="itemWrap">
                    <Button variant="contained" disabled={data.status == '' || data.status == 'Pending'} className='viewCardBtn_mobile' onClick={()=>this.openPayCard(data, index)}>
                             VIEW PAYMENT CARD
                       </Button>
                    </Grid>

              </Grid>
                </AccordionDetails>
              </Accordion>
           ))}
        </div>
      );
    }
    openPayCard =(rowData, i)=>{
      this.props.openPaymentCard(rowData, i)
    }
}

