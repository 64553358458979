import { Component } from 'react';
import axios from 'axios';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NotificationIcon from '../../Assets/my_notifications_icon_active.svg';
import CloseIcon from '@material-ui/icons/Close';
import firebase from '../../firebase';
import moment from 'moment';
import ReminderNotification from '../Header/Images/notification_reminder_icon.svg';
import AlertNotification from '../Header/Images/notification_alert_icon.svg'
import UpdateNotification from '../Header/Images/notification_update_icon.svg'
import SurveyNotification from '../Header/Images/notification_survey_icon.svg'
import PromoNotification from '../Header/Images/notification_promo_icon.svg'
import AnnouncementNotification from '../Header/Images/notification_announcement_icon.svg'
import Button from '@material-ui/core/Button';
import { Modal } from 'react-bootstrap';
import { Grid, IconButton} from "@material-ui/core";
// const NextButton = withStyles(customStyle.viewBtn)(Button)
export default class MyNotification extends Component{
    constructor(props) {
      super(props)
      this.state = {
        mail:JSON.parse(localStorage.getItem('authUser')).attributes.email,
      }
      
    }
componentDidMount(){
    var authUser = localStorage.getItem('authUser');
        var user = JSON.parse(authUser)
        this.setState({username: user.attributes.name})
        this.getNotification()
        const msg = firebase.messaging.isSupported() ? firebase.messaging() : null;
        if (msg) {
          msg.onMessage(payload => {
            this.getNotification()
            const myEvent = new CustomEvent("myevent", {
              detail: {},
              bubbles: true,
              cancelable: true,
              composed: false,
            })
            document.dispatchEvent(myEvent);
            console.log("onMessage:+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++home ", payload);
          });
        }
}
getNotification = () => {
    axios.get(process.env.REACT_APP_BASE_URL+"v8/Twillio/"+'getNotificationDetails/' + this.state.mail)
      .then(res => {
        if (res.data && res.data.response) {
          this.setState({
            notificationData: res.data.response.notificationDetails,
            notificationCount: res.data.response.count
          }, () => {
            if (this.state.notificationData) {
              let dt = this.state.notificationData.filter((val, index) => (val.status == 'sent'))
              let newArr = dt.slice(0, 5)
              this.setState({ unReadData: dt })
            }
          })
        } else {
          this.setState({ notificationData: [] })
        }
      })
  }
    render(){
        return (<div>
                <div className="cardwallet_back_notification">
                    <div className="NotificationCard-BG text-center">
                        <div className="cardwallet_onover_backdivMain">
                            <img src={NotificationIcon} className="cardwaalet_img_backside" style={{width:'36px'}}/>
                            <div className="notification_label_backside">My Notifications</div>
                            <div style={{paddingLeft:0}} class="crossButton"> {this.props.modal ?<CloseIcon onClick={()=>this.props.closeModal()} style={{color:'#818181', cursor:"pointer"}}/> : <MoreVertIcon style={{color:'#818181'}}/>}</div>
                            {/* <div > <MoreVertIcon style={{color:'#818181'}}/></div> */}
                        </div>
                    </div>
                    {this.state.unReadData && this.state.unReadData.length > 0 ?
                        <div className="notificationTray">
                            {
                                 this.state.unReadData.slice(0, 5).map((data, idx) => {
                                    return (data.type == 'REMINDER' || data.type == 'reminder') ?
                                    <div key={idx} class="cardwallet_onover_backdiv" onClick={() => this.onClickUpdateNotificationStatus(data.notificationID, data.urlPath)}>
                                      <img src={ReminderNotification} class="Notification_category_img" />
                                      <div class="notification_category_Message">
                                        <p class="notification_category_label">{data.title}
                                          <span className="notification_details_Date">{this.dateformat(data.created_date)}</span>

                                        </p>
                                        <div style={{ color: '#4e4e4e' }}>{data.message}</div>
                                      </div>
                                    </div>
                                    :
                                    (data.type == 'ALERT' || data.type == 'alert') ?
                                      <div key={idx} class="cardwallet_onover_backdiv" onClick={() => this.onClickUpdateNotificationStatus(data.notificationID, data.urlPath)}>
                                        <img src={AlertNotification} class="Notification_category_img" />
                                        <div class="notification_category_Message">
                                          <p class="notification_category_label">{data.title}
                                            <span className="notification_details_Date">{this.dateformat(data.created_date)}</span>

                                          </p>
                                          <div style={{ color: '#4e4e4e' }}>{data.message}</div>
                                        </div>
                                      </div>
                                      :
                                      (data.type == 'UPDATE' || data.type == 'update') ?
                                        <div key={idx} class="cardwallet_onover_backdiv" onClick={() => this.onClickUpdateNotificationStatus(data.notificationID, data.urlPath)}>
                                          <img src={UpdateNotification} class="Notification_category_img" />
                                          <div class="notification_category_Message">
                                            <p class="notification_category_label">{data.title}
                                              <span className="notification_details_Date">{this.dateformat(data.created_date)}</span>

                                            </p>
                                            <div style={{ color: '#4e4e4e' }}>{data.message}</div>
                                          </div>
                                        </div>
                                        :
                                        (data.type == 'SURVEY' || data.type == 'survey') ?
                                          <div key={idx} class="cardwallet_onover_backdiv" onClick={() => this.onClickUpdateNotificationStatus(data.notificationID, data.urlPath)}>
                                            <img src={SurveyNotification} class="Notification_category_img" />
                                            <div class="notification_category_Message">
                                              <p class="notification_category_label">{data.title}
                                                <span className="notification_details_Date">{this.dateformat(data.created_date)}</span>

                                              </p>
                                              <div style={{ color: '#4e4e4e' }}>{data.message}</div>
                                            </div>
                                          </div>
                                          :
                                          (data.type == 'PROMOTIONAL' || data.type == 'promotional') ?
                                            <div key={idx} class="cardwallet_onover_backdiv" onClick={() => this.onClickUpdateNotificationStatus(data.notificationID, data.urlPath)}>
                                              <img src={PromoNotification} class="Notification_category_img" />
                                              <div class="notification_category_Message">
                                                <p class="notification_category_label">{data.title}
                                                  <span className="notification_details_Date">{this.dateformat(data.created_date)}</span>

                                                </p>
                                                <div style={{ color: '#4e4e4e' }}>{data.message}</div>
                                              </div>
                                            </div>
                                            :
                                            // (data.type == 'ANNOUNCEMENT' || data.type == 'announcement') ?
                                            //   <div key={idx} class="cardwallet_onover_backdiv" onClick={() => this.onClickUpdateNotificationStatus(data.notificationID, data.urlPath,data.type)}>
                                            //     <img src={AnnouncementNotification} class="Notification_category_img" />
                                            //     <div class="notification_category_Message">
                                            //       <p class="notification_category_label">{data.title}
                                            //         <span className="notification_details_Date">{this.dateformat(data.created_date)}</span>

                                            //       </p>
                                            //       <div style={{ color: '#4e4e4e' }}>{data.message}</div>
                                            //     </div>
                                            //   </div>
                                              // :
                                              null
                                 })
                            }
                        </div>:
                        <div style={{padding:35}}>
                        <div className="tempModalTxt">You currently have no new notifications. </div>
                    </div>}
                </div> 

                <Modal  show={this.state.noticeModal}  centered className="ChangPayModal paymentWalletModal" backdrop='static'>
                        <Modal.Body style={{maxHeight:'410px',overflowY:'auto'}}>
                        <Grid
                                                container
                                                direction="row"
                                                justify="space-between"
                                                alignItems="flex-start"
                                                style={{flexWrap:'nowrap'}}
                                            >
                                                <span class="Change-Payment-Method" style={{lineHeight:'1.3'}}>Message</span>
                                                <IconButton aria-label="close"  onClick={() => this.setState({ noticeModal: false })} style={{marginTop:'-13px'}}>
                                                <CloseIcon />
                                                </IconButton>
                                            </Grid>
                            <div>
                            <Grid container>
                            </Grid>
                                <pre class="The-fellowship-incurs-additional-fees-related-to-p" style={{margin:'0px',padding:'15px',overflowX: 'auto',
                                whiteSpace: "pre-wrap"}}>
                            We’re facing some technical difficulties, due to
                            which this feature is currently unavailable. For
                            support, call Member Services at (855) 030-4941, Monday through Friday, 8.00am to
                            8.00pm CST.</pre>
                        </div>
                            </Modal.Body>
                        <Modal.Footer style={{justifyContent: 'center'}}>
                            <Button variant="contained" onClick={()=>this.setState({noticeModal:false})} style={{marginRight:'15px'}} className="disablecardPopup">
                                               Close
                                                </Button>
                        </Modal.Footer>
                        </Modal>
         
        </div>)
    }
    onClickUpdateNotificationStatus=(id,url)=>{
        console.log("Notification ID ====",id,url)
        let obj={"notificationId":id}
        axios.post(process.env.REACT_APP_BASE_URL+"v8/Twillio/"+"updateNotificationStatus", obj).then(response=>{
          if(url=='ProgramInformation'){
            window.location.href='/MemberInformation' 
          }
          if(url=='Needs'||url=='MyNeeds'){
            window.location.href='/MemberNeeds'
          }
          if(url=='MobileTransaction'){
            // window.location.href='/PaymentWallet'
            this.setState({noticeModal:true})
          }
        })
       }
       dateformat(date) {
        const enrollmentDate = this.getDateInUTC(date, true)
        let nowdate = this.getDateInUTC(new Date(), true)
        var day = moment(enrollmentDate).format('DD')
        var mon = moment(enrollmentDate).format('MM')
        var year = moment(enrollmentDate).format('YYYY')
        var date = mon + "/" + day + "/" + year
        var todayDate = moment(nowdate).format('MM/DD/YYYY')
        let hr = moment(enrollmentDate).format('hh')
    
        if (date !== todayDate) {
          return moment(date).format('MMM DD')
        } else {
          if (hr == '00') {
            return moment(enrollmentDate).format('m') + 'mins'
          }
          if (hr > 12) {
            return moment(enrollmentDate).format('hh:mm A')
          } else {
            return moment(enrollmentDate).format('hh:mm A')
          }
        }
      }
      getDateInUTC = (date, getInMillisecs) => {
        if (date) {
          let newDateTime = new Date(date)
          return new Date(newDateTime)
        }
        return date
      }
}

